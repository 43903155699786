<template>
  <div>
    <div class="material-group">
      <input
        ref="previewPhoneInput"
        class="material-input form-input-phone"
        placeholder=""
        type="text"
        required
      />
    </div>
  </div>
</template>

<script>
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "Mobile Number",
    },
  },

  data() {
    return {
      phoneNumber: "",
      labelElem: undefined,
    };
  },

  mounted() {
    const element = this.$refs.previewPhoneInput;
    const options = {
      customPlaceholder: function () {
        return "";
      },
      formatOnDisplay: true,
      nationalMode: true,
      separateDialCode: false,
      utilsScript: require("../../../node_modules/intl-tel-input/build/js/utils.js"),
      initialCountry: "auto",
      allowDropdown: false,
      geoIpLookup: (success, failure) => {
        const countryCode = "US";
        fetch("https://ip2c.org/s")
          .then((response) => response.json())
          .then((data) => {
            let matches = data.split(";");
            if (matches && matches.hasOwnProperty("1"))
              countryCode = matches[1];
            success(countryCode);
          })
          .catch((error) => {
            success(countryCode);
          });
      },
    };

    const obj = intlTelInput(element, options);

    element.addEventListener('keyup', () => this.fomratNational(obj));
    element.addEventListener('change', () => this.fomratNational(obj));

    var parent = element.closest(".material-group .iti");

    const ele1 = document.createElement("span");
    ele1.className = "material-highlight";
    const ele2 = document.createElement("span");
    ele2.className = "material-bar";
    const ele3 = document.createElement("label");
    ele3.className = "material-label";
    ele3.innerHTML = `${this.label}<span>*</span>`;
    this.labelElem = ele3

    parent.appendChild(ele1);
    parent.appendChild(ele2);
    parent.appendChild(ele3);
  },

  watch: {
    label: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.labelElem.innerHTML = `${this.label}<span>*</span>`;
        }
      },
    },
  },

  methods: {
    fomratNational(phoneIntlObj) {
      if (typeof intlTelInputUtils !== 'undefined') {
          var currentText = phoneIntlObj.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') {
              phoneIntlObj.setNumber(currentText)
          }
      }
    }
  },
};
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
</style>