<template>
  <div v-if="user" class="install-page">
    <vuestic-widget :loading="loading" :headerText="`Add/Edit Install`">
      <div class="row" v-if="form.number">
        <div class="col-md-12" v-if="!form.twilio_sms && form.widget_type != 1">
          <b-alert class="err-message text-center" show variant="danger" v-html="smsAlertMessage"></b-alert>
        </div>
        <div class="col-md-12" v-if="!form.twilio_voice &&
          !(callerIdEnabled && form.caller_id && form.callback_phonenumber) &&
          form.widget_type != 2
          ">
          <b-alert class="err-message text-center" show variant="danger" v-html="callAlertMessage"></b-alert>
        </div>
        <div class="col-md-12" v-if="showSmsRestriction">
          <b-alert class="err-message text-center" show variant="primary">For text messaging it is always best practices
            to use numbers
            within the same country as the end user's device. We can't guarantee
            users can receive text messages from countries outside of
            <span><b>{{ countries[twilioNumberCountry] }}</b></span>
            (a different country than your marketing number).</b-alert>
        </div>
        <div class="col-md-12" v-if="twilioStatus === 'invalid'">
          <b-alert class="err-message text-center" show variant="danger">Your number <b>{{ twilioNumber }}</b> doesn't
            exist in your account. Please assign new number to move forward.
          </b-alert>
        </div>
      </div>
      <vuestic-wizard :steps="installSteps" v-on:wizardComplete="wizardCompleted()" :processing="processing" v-if="form">
        <div slot="settings" class="form-wizard-tab-content settings">
          <div class="row mb-0">
            <div class="col-md-12 headline">
              <p>
                <strong>{{
                  widgetLabels[`${callMessageText}-company-number-headline`]
                }}</strong><br />
                <small>{{
                  widgetLabels[`${callMessageText}-company-number`]
                }}</small>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <twilio-number-selector @onChange="onChangeTwilioNumber" v-model="twilioNumber" id="area-phone"
                :install-id="form.id" :landline-enabled="businessLandlineEnabled && businessWidgetType == 2
                  " :twilio-status="twilioStatus" />
            </div>
            <div class="col-md-6 col-sm-12">
              <text-input name="url" validate="required" v-model="form.properties.website" label="Website URL"
                tooltip="URL of the website on which the widget will be placed" />
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="input-label has-tool-tip">Tags <a v-b-tooltip.hover
                  title="Tags will be assigned to the contacts created from this install"
                  class="fa fa-question-circle tooltip-icon" /></label>
              <tag-selector :show-label="false" v-model="form.tags"></tag-selector>
            </div>
            <div class="col-md-6 col-sm-12">
              <text-input name="marketingSource" v-model="form.properties.marketingSource" label="Marketing Source"
                tooltip="The Marketing Source is the origin of where the contact found your company (How did you hear about us?). We will send this information to zaiper along with contact data. Leave blank if you don't use this." />
            </div>
          </div>
          <div class="row" v-if="showDefaultBusinessNumber">
            <div class="col-md-6 col-sm-12">
              <phone-number-input name="Phone Number" v-model="form.callback_phonenumber" label="Default Business Number"
                :tooltip="`If someone calls your assigned number it will be forwarded to this number`" />
            </div>
            <div class="col-md-6 col-sm-12" v-if="businessCallerIdEnabled">
              <div class="form-group caller-id-block">
                <div class="input-group has-margin-top" v-if="!form.caller_id">
                  <label class="control-label">Caller ID
                    <a v-b-tooltip.hover
                      title="Set your caller ID for us to use your default business number as your caller id. Calls to your leads will be made from your default business number when the caller ID is set"
                      class="fa fa-question-circle tooltip-icon" />
                  </label>
                  <button class="btn btn-sm btn-primary btn-phone-verify" @click="checkCallerID()"
                    :disabled="isVerifyLoading">
                    <span v-if="isVerifyLoading" class="atom-spinner-wrapper">
                      <atom-spinner slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                    </span>
                    <span v-else>Set Caller ID</span>
                  </button>
                </div>
                <div v-if="form.caller_id">
                  <label class="lelabel">Deactivate Caller ID
                    <a v-b-tooltip.hover
                      title="If you deactivate this caller id, We will use twilio phone number for outgoing calls and messages."
                      class="fa fa-question-circle tooltip-icon" />
                  </label>
                  <button class="btn btn-sm btn-danger btn-phone-verify" @click="disableCallerID()"
                    :disabled="isVerifyLoading">
                    <span v-if="isVerifyLoading" class="atom-spinner-wrapper">
                      <atom-spinner slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                    </span>
                    <span v-else>Deactivate Caller ID</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="showLandlineSection">
            <template v-if="form.landline_enabled">
              <div class="col-md-6 col-sm-12" v-if="businessWidgetType == 3">
                <phone-number-input name="Landline Number" v-model="form.landline_number" label="Landline Number"
                  :disabled="true" :tooltip="`This is the main number of the business that we will use for messages.`" />
              </div>
              <div class="col-md-6 col-sm-12">
                <label class="lelabel">Deactivate Landline Number
                  <a v-b-tooltip.hover
                    title="If you deactivate this landline number, We will use twilio phone number for outgoing messages"
                    class="fa fa-question-circle tooltip-icon" />
                </label>
                <button class="float-left btn btn-sm btn-danger" @click="onDisableLandline()">
                  Disable Landline Number
                </button>
              </div>
            </template>
            <div class="col-md-6 col-sm-12" v-else>
              <label class="lelabel">Landline Number
                <a v-b-tooltip.hover title="This is the main number of the business that we will use for messages"
                  class="fa fa-question-circle tooltip-icon" />
              </label>
              <button class="float-left btn btn-sm btn-primary" @click="onClickLandline()">
                <i class="fa fa-plus" /> Add Landline Number
              </button>
            </div>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="companyInfoForm">
            <form @submit.prevent="handleSubmit()">
              <div class="row mt-5">
                <div class="col-md-12">
                  <p>
                    <strong>Company Contact Info</strong><br />
                    <small>We will use this information to build company vCard and send to contacts.<br/>You can upload profile image in business setting</small>
                  </p>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <text-input name="companyName" validate="required" v-model="form.properties.vcard_name" label="Company Name" />
                    </div>
                    <div class="col-md-12">
                      <phone-number-input name="Phone Number" v-model="form.properties.vcard_phone" label="Company Phone" />
                    </div>
                    <div class="col-md-12">
                      <text-input type="email" name="companyEmail" validate="required" v-model="form.properties.vcard_email" label="Company Email" />
                    </div>
                    <div class="col-md-12">
                      <text-input name="companyWebsite" validate="required" v-model="form.properties.vcard_website" label="Company Website" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 vcard-preview-wrapper">
                  <div class="vcard-preview">
                    <div class="vcard-logo" :style="vcardLogoStyle"/>
                    <span class="vcard-name">{{ vcardInfo.name }}</span>
                    <span class="vcard-phone">{{ vcardInfo.phone | phone('international') }}</span>
                    <span class="vcard-email">{{ vcardInfo.email }}</span>
                    <span class="vcard-website">{{ vcardInfo.website }}</span>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div slot="agents" class="form-wizard-tab-content agents">
          <div class="row" v-if="businessDepartmentEnabled">
            <div class="col-md-12">
              <div style="width: 300px">
                <label class="lelabel">Show Department</label>
                <vuestic-switch v-model="form.department_enabled" class="align-middle" :offcolor="true">
                  <span slot="trueTitle">Yes</span>
                  <span slot="falseTitle">No</span>
                </vuestic-switch>
              </div>
            </div>
          </div>
          <div v-if="!departmentEnabled">
            <template v-if="businessCallEnabled">
              <div class="row mt-2">
                <div class="col-md-9">
                  <p class="mr-5">
                    <strong>Specify the Voice Agents</strong><br />
                    <small>List of agents to accept the voice call.</small>
                  </p>
                </div>
                <div class="col-md-3">
                  <button type="button" class="float-right btn btn-primary" @click="addAgent('voice')">
                    <i class="fa fa-plus" /> Add
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-agents">
                    <tbody>
                      <tr v-for="(item, index) in form.voiceAgents" :key="index">
                        <td width="30%">
                          <b>{{ item.full_name }}</b><br /><small>{{ item.email }}</small>
                        </td>
                        <td width="10%">
                          <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                          <span v-else class="badge badge-default">User</span>
                        </td>
                        <td width="30%">{{ item.phone }}</td>
                        <td width="20%" class="notification-td">
                          <span v-if="item.call || item.mail_call" v-b-tooltip.hover title="Call Notification"
                            class="text-primary tooltip-icon"><i class="fa fa-phone"></i></span>
                        </td>
                        <td width="10%">
                          <button type="button" class="btn btn-danger btn-sm" @click="deleteAgent('voice', index)">
                            <i class="fa fa-trash-o" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <template v-if="businessSmsEnabled">
              <div class="row mt-2">
                <div class="col-md-9">
                  <p class="mr-5">
                    <strong>Specify the SMS Agents</strong><br />
                    <small>List of agents to accept manage sms requests.</small>
                  </p>
                </div>
                <div class="col-md-3">
                  <button type="button" class="float-right btn btn-primary" @click="addAgent('sms')">
                    <i class="fa fa-plus" /> Add
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-agents">
                    <tbody>
                      <tr v-for="(item, index) in form.smsAgents" :key="index">
                        <td width="30%">
                          <b>{{ item.full_name }}</b><br /><small>{{ item.email }}</small>
                        </td>
                        <td width="10%">
                          <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                          <span v-else class="badge badge-default">User</span>
                        </td>
                        <td width="30%">{{ item.phone }}</td>
                        <td width="20%" class="notification-td">
                          <span v-if="item.sms || item.mail_sms" v-b-tooltip.hover title="SMS Notification"
                            class="text-primary tooltip-icon"><i class="fa fa-comments"></i></span>
                        </td>
                        <td width="10%">
                          <button type="button" class="btn btn-danger btn-sm" @click="deleteAgent('sms', index)">
                            <i class="fa fa-trash-o" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
          <div v-if="departmentEnabled">
            <div class="row mb-0">
              <div class="col-md-12 headline">
                <p class="mr-5">
                  <strong>Deparments</strong><br />
                  <small>List of departments that are shown in widget</small>
                </p>
                <button class="btn btn-primary" @click="addDepartment()">
                  <i class="fa fa-plus" /> Add
                </button>
              </div>
            </div>
            <div class="row" v-for="(department, index) in form.departments" :key="index">
              <div class="col-sm-12 col-md-12 mb-1">
                <div class="d-flex align-items-center justify-content-between" style="max-width: 1024px">
                  <div class="department-title-wrapper">
                    <h5 class="text-primary font-weight-bold my-0 mr-5">
                      {{ department.name }}
                    </h5>
                    <text-input name="order" v-model="department.order" placeholder="order" />
                  </div>
                  <div style="width: 200px">
                    <button type="button" class="btn btn-primary btn-sm mr-2" @click="showDepartment(index)">
                      <span v-if="!department.show">
                        <i class="fa fa-chevron-down" />
                      </span>
                      <span v-if="department.show">
                        <i class="fa fa-chevron-up" />
                      </span>
                    </button>
                    <button class="btn btn-danger btn-sm" @click="deleteDepartmentItem(index)">
                      <i class="fa fa-trash-o" />
                    </button>
                  </div>
                </div>
              </div>
              <template v-if="department.show">
                <div v-if="department.marketing_source" class="col-md-12">
                  <p class="font-weight-bold">Marketing Source: {{ department.marketing_source }} </p>
                </div>
                <div v-if="widgetTypePhone" class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mr-5 my-1 font-italic">
                        <strong>Voice Agents</strong><br />
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <table class="table table-agents">
                        <tbody>
                          <tr v-for="(item, index) in department.voiceAgents" :key="index">
                            <td width="30%">
                              <b>{{ item.full_name }}</b><br /><small>{{ item.email }}</small>
                            </td>
                            <td width="20%">
                              <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                              <span v-else class="badge badge-default">User</span>
                            </td>
                            <td width="30%">{{ item.phone }}</td>
                            <td width="20%" class="notification-td">
                              <span v-if="item.call || item.mail_call" v-b-tooltip.hover title="Call Notification"
                                class="text-primary tooltip-icon"><i class="fa fa-phone"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div v-if="widgetTypeText" class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mr-5 my-1 font-italic">
                        <strong>SMS Agents</strong><br />
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <table class="table table-agents">
                        <tbody>
                          <tr v-for="(item, index) in department.smsAgents" :key="index">
                            <td width="30%">
                              <b>{{ item.full_name }}</b><br /><small>{{ item.email }}</small>
                            </td>
                            <td width="20%">
                              <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                              <span v-else class="badge badge-default">User</span>
                            </td>
                            <td width="30%">{{ item.phone }}</td>
                            <td width="20%" class="notification-td">
                              <span v-if="item.sms || item.mail_sms" v-b-tooltip.hover title="SMS Notification"
                                class="text-primary tooltip-icon"><i class="fa fa-comments"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div slot="notifications" class="form-wizard-tab-content notifications">
          <template v-if="businessCallEnabled">
            <div class="row mb-0">
              <div class="col-md-12 headline">
                <p>
                  <strong>{{
                    widgetLabels[`call-notification-headline`]
                  }}</strong><br />
                  <small>{{ widgetLabels[`call-notification`] }}</small>
                </p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div class="vuestic-switch-container">
                  <phone-number-input label="SMS phone number" name="Phone Number" :allowValidation="false"
                    v-model="form.notification_phone" class="voice-smsphone" />

                  <vuestic-switch v-model="notificationPhoneEnable" :offcolor="true">
                    <span slot="trueTitle">Yes</span>
                    <span slot="falseTitle">No</span>
                  </vuestic-switch>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="vuestic-switch-container">
                  <text-input label="Email" name="email" v-model="form.notification_email" class="voice-email" />
                  <vuestic-switch v-model="notificationEmailEnable" :offcolor="true">
                    <span slot="trueTitle">Yes</span>
                    <span slot="falseTitle">No</span>
                  </vuestic-switch>
                </div>
              </div>
            </div>
          </template>
          <template v-if="businessSmsEnabled">
            <div class="row mb-0">
              <div class="col-md-12 headline">
                <p>
                  <strong>{{
                    widgetLabels[`message-notification-headline`]
                  }}</strong><br />
                  <small>{{ widgetLabels[`message-notification`] }}</small>
                </p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div class="vuestic-switch-container">
                  <phone-number-input label="SMS phone number" name="Phone Number" :allowValidation="false"
                    v-model="form.sms_notification_phone" class="voice-smsphone" />

                  <vuestic-switch v-model="smsNotificationPhoneEnable" :offcolor="true">
                    <span slot="trueTitle">Yes</span>
                    <span slot="falseTitle">No</span>
                  </vuestic-switch>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="vuestic-switch-container">
                  <text-input label="Email" name="email" v-model="form.sms_notification_email" class="voice-email" />
                  <vuestic-switch v-model="smsNotificationEmailEnable" :offcolor="true">
                    <span slot="trueTitle">Yes</span>
                    <span slot="falseTitle">No</span>
                  </vuestic-switch>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- PAGE 2  HOURS -->
        <div slot="officeHours" class="form-wizard-tab-content officeHours">
          <p>
            <strong>{{
              widgetLabels[`${callMessageText}-officehour-headline`]
            }}</strong><br />
            <small>{{ widgetLabels[`${callMessageText}-officehour`] }}</small>
          </p>
          <div id="" class="hours">
            <div class="row align-items-center" v-for="(obj, day) in form.properties.hours" :key="day">
              <div class="col-1">
                <strong>{{ day.toUpperCase() }}</strong>
              </div>
              <div class="col">
                <time-selector v-model="obj.openTime" label="Open" :disabled="obj.fullOpen" />
              </div>
              <div class="col">
                <time-selector v-model="obj.closeTime" label="Close" :disabled="obj.fullOpen" />
              </div>
              <div class="col">
                <div class="checkbox abc-checkbox abc-checkbox-primary">
                  <input type="checkbox" name="schedule" :id="`country-group-${day}`" value="Daily"
                    v-model="obj.fullOpen" />
                  <label :for="`country-group-${day}`">
                    <span class="abc-label-text">24 hours</span>
                  </label>
                </div>
              </div>
              <div class="col-2">
                <vuestic-switch v-model="obj.open" class="align-middle" :offcolor="true">
                  <span slot="trueTitle">Open</span>
                  <span slot="falseTitle">Closed</span>
                </vuestic-switch>
              </div>
              <div class="col">
                <button class="btn btn-primary btn-sm align-middle" v-if="day == 'mon'" @click="populateTimes(obj)">
                  APPLY TO ALL
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="">
                <label class="lelabel">Timezone</label>
                <multiselect v-model="form.properties.timezone" :options="timeZonesForSelect" :multiple="false"
                  :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder=""
                  label="description" track-by="value" :preselect-first="true" :show-labels="false" :allowEmpty="false">
                </multiselect>
              </div>
              <div v-if="localTimeZone">
                <small>Local Timezone: {{ localTimeZone.description }}</small><br />
                <small>Local Time: {{ localTime }}</small><br />
                <small>UTC Time: {{ utcTime }}</small><br />
              </div>
            </div>
          </div>
        </div>
        <!-- PAGE 3 APPS -->
        <div slot="apps" class="form-wizard-tab-content">
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="setting-block">
                <div class="left-wrapper">
                  <div class="setting-primary-color-wrapper d-flex">
                    <!--widget color-->
                    <div class="setting-color-wrapper">
                      <label class="lelabel d-block w-100" for="primaryColor">
                        Widget Primary Color
                        <a v-b-tooltip.hover title="( You can customize the color of the widget to match your website )"
                          class="fa fa-question-circle tooltip-icon" />
                      </label>
                      <div class="widget-color-wrapper">
                        <div class="widget-color-selector-div">
                          <vuestic-color-picker-input class="pull-left rb-color-picker"
                            v-model="form.properties.widgetPrimaryColor" mode="advanced">
                            <vuestic-color-square :value="form.properties.widgetPrimaryColor" id="primaryColor" />
                            <text-input class="color-text" name="Widget Color"
                              v-model="form.properties.widgetPrimaryColor" :disabled="true" />
                          </vuestic-color-picker-input>
                        </div>
                        <div class="widget-button-preview" :style="previewWrapperStyle">
                          <button v-if="!form.properties.widgetIcon" class="widget-button">
                            <img src="../../assets/images/widget/phone-icon.webp" alt="Phone Icon" />
                          </button>
                          <div v-else class="widget-button-custom">
                            <img :src="widgetIconImg" height="50" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--widget icon-->
                    <div class="ml-5">
                      <div class="form-group">
                        <div class="input-group input-group-widget-icon">
                          <label class="lelabel">Widget Icon (Max 1MB)</label>
                          <vuestic-file-upload type="single" :dropzone="false" fileTypes=".png, .jpg, .jpeg, .gif, .webp"
                            v-model="fileWidgetIcon" v-show="!form.properties.widgetIcon" />

                          <div class="logo-preview" v-if="form.properties.widgetIcon">
                            <div class="actions">
                              <button class="btn btn-primary btn-sm align-middle" @click="deleteWidgetIcon">
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="align-self-center overlay-dim" v-if="spinner.fileWidgetIcon.active">
                          <atom-spinner :animation-duration="1000" :size="120" :color="spinner.fileWidgetIcon.color" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="setting-position-img-wrapper row mt-5">
                    <!--widget position-->
                    <div class="setting-position-wrapper col-md-3">
                      <label class="lelabel" for="widgetPositionRight">Widget Position</label>
                      <div class="row">
                        <div class="col-6">
                          <div class="radio abc-radio abc-radio-primary">
                            <input type="radio" name="widgetPosition" id="widgetPositionLeft" value="left"
                              v-model="form.properties.widgetPosition" />
                            <label for="widgetPositionLeft">
                              <span class="abc-label-text">Left</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="radio abc-radio abc-radio-primary">
                            <input type="radio" name="widgetPosition" id="widgetPositionRight" value="right"
                              v-model="form.properties.widgetPosition" />
                            <label for="widgetPositionRight">
                              <span class="abc-label-text">Right</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="setting-position-switch col-md-3">
                      <label class="lelabel">Pop Up To Center</label>
                      <vuestic-switch v-model="form.properties.popupCenterEnabled" class="align-middle" :offcolor="true">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                    <!--company logo-->
                    <div class="setting-img-wrapper form-group col-md-3">
                      <div class="input-group input-group-company-logo">
                        <label class="lelabel">Company Logo (128 x 128 Optimal)</label>
                        <vuestic-file-upload type="single" :dropzone="false" fileTypes=".png, .jpg, .jpeg, .gif, .webp"
                          v-model="fileUploadLogos" v-show="!this.form.properties.logo" />

                        <div class="logo-preview" v-if="this.form.properties.logo">
                          <div class="actions">
                            <button class="btn btn-primary btn-sm align-middle" @click="deleteLogo">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="align-self-center overlay-dim" v-if="spinner.fileUploadLogos.active">
                        <atom-spinner :animation-duration="1000" :size="120" :color="spinner.fileUploadLogos.color" />
                      </div>
                    </div>
                    <div class="col-md-12 widget-margin-wrapper">
                      <div class="widget-margin-item">
                        <text-input type="number" name="widgetMarginX" label="Margin X"
                          v-model="form.properties.widgetMarginX" :required="true" />
                        <span class="pl-2">Pixels</span>
                      </div>
                      <div class="widget-margin-item">
                        <text-input type="number" name="widgetMarginY" label="Margin Y"
                          v-model="form.properties.widgetMarginY" :required="true" />
                        <span class="pl-2">Pixels</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3 mb-5 widget-type-select">
                    <div class="col-md-12" style="max-width: 500px">
                      <label class="lelabel">Widget Type</label>
                      <multiselect v-model="widgetType" :options="widgetTypeArr" :multiple="false" :close-on-select="true"
                        :clear-on-select="false" :preserve-search="true" placeholder="" label="label" track-by="label"
                        :preselect-first="true" :show-labels="false" :searchable="false"
                        :disabled="businessWidgetType != 3" :allowEmpty="false"></multiselect>
                    </div>
                  </div>

                  <div class="row mt-3 mb-5">
                    <div class="col-md-6" style="max-width: 500px">
                      <label class="lelabel">Enable Voice Request
                        <a v-b-tooltip.hover title="You can allow contacts to send you VoiceRecord via widget"
                          class="fa fa-question-circle tooltip-icon" />
                      </label>
                      <vuestic-switch v-model="form.properties.voiceRecordingEnabled" class="align-middle"
                        :offcolor="true">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                    <div v-if="form.properties.voiceRecordingEnabled" class="col-md-6 text-left selec-div"
                      style="max-width: 500px">
                      <div class="form-group">
                        <div class="input-group">
                          <select v-model="form.properties.voiceRecordingLimit" class="form-control">
                            <option v-for="min in [1, 2, 3, 4, 5]" :key="min" :value="min">
                              {{ min }}
                            </option>
                          </select>
                          <label class="control-label">Recording limit (mins)</label>
                          <i class="bar"></i>
                          <i class="ion ion-ios-arrow-down icon-right input-icon custom-input-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- language selection start -->
                  <div class="row">
                    <div class="col-md-6 text-left selec-div" style="max-width: 500px">
                      <div class="row">
                        <div class="col-md-12">
                          <label class="field-label">Language</label>
                          <multiselect v-model="selectedMainLanguage" :options="mainLanguageList" :multiple="false"
                            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="" label="label"
                            track-by="label" :preselect-first="true" :show-labels="false" :allowEmpty="false" @input="onSelectLanguage">
                          </multiselect>
                        </div>

                        <button class="btn btn-primary m-4" type="button" @click="openModalLanguage">
                          <span>Add Language</span>
                        </button>

                        <button v-if="selectedMainLanguage.id != 'en'" class="btn btn-danger m-4" type="button" @click="removeLanguage">
                          <span>Delete Language</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- language selection end -->
                  <!--header title-->
                  <div class="row">
                    <div class="col-md-12">
                      <textarea-input name="headerTitle" :label="fieldConfig.widgetSetting.headerTitle.label" :max-length="fieldConfig.widgetSetting.headerTitle.textLimit
                        " v-model="form.properties.languageList[mainLanguageIndex].value.headerTitle" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--department text-->
                  <div class="row" v-if="departmentEnabled">
                    <div class="col-md-12">
                      <textarea-input name="department" :label="fieldConfig.widgetSetting.departmentText.label"
                        :max-length="fieldConfig.widgetSetting.departmentText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.departmentText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--gate text-->
                  <div class="row" v-if="gateEnabled">
                    <div class="col-md-12">
                      <textarea-input name="gate" :label="fieldConfig.widgetSetting.gateText.label" :max-length="fieldConfig.widgetSetting.gateText.textLimit
                        " v-model="form.properties.languageList[mainLanguageIndex].value.gateText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <div class="row" v-if="gateEnabled">
                    <!--call connect text-->
                    <div class="col-md-6" v-if="callEnabled">
                      <textarea-input name="callConnectText" :label="fieldConfig.widgetSetting.callConnectText.label"
                        :max-length="fieldConfig.widgetSetting.callConnectText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.callConnectText" :required="true" @focus="handleFocus" :rows="1"
                        @blur="handleBlur" />
                    </div>
                    <!--sms connect text-->
                    <div class="col-md-6" v-if="smsEnabled">
                      <textarea-input name="smsConnectText" :label="fieldConfig.widgetSetting.smsConnectText.label"
                        :max-length="fieldConfig.widgetSetting.smsConnectText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.smsConnectText" :required="true" @focus="handleFocus" :rows="1"
                        @blur="handleBlur" />
                    </div>
                    <!--voice connect text-->
                    <div class="col-md-6" v-if="voiceRecordEnabled">
                      <textarea-input name="recordConnectText" :label="fieldConfig.widgetSetting.recordConnectText.label"
                        :max-length="fieldConfig.widgetSetting.recordConnectText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.recordConnectText" :required="true" @focus="handleFocus" :rows="1"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--intro text-->
                  <div class="row" v-if="callEnabled">
                    <div class="col-md-12">
                      <textarea-input name="intro" :label="fieldConfig.widgetSetting.introText.label" :max-length="fieldConfig.widgetSetting.introText.textLimit
                        " v-model="form.properties.languageList[mainLanguageIndex].value.introText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--offline text-->
                  <div class="row" v-if="callEnabled">
                    <div class="col-md-12">
                      <textarea-input name="offline" :label="fieldConfig.widgetSetting.offlineText.label" :max-length="fieldConfig.widgetSetting.offlineText.textLimit
                        " v-model="form.properties.languageList[mainLanguageIndex].value.offlineText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--sms text-->
                  <div class="row" v-if="smsEnabled">
                    <div class="col-md-12">
                      <textarea-input name="SmsText" :label="fieldConfig.widgetSetting.smsText.label" :max-length="fieldConfig.widgetSetting.smsText.textLimit
                        " v-model="form.properties.languageList[mainLanguageIndex].value.smsText" :required="true" @focus="handleFocus" @blur="handleBlur" />
                    </div>
                  </div>

                  <!--voice record text-->
                  <div class="row" v-if="voiceRecordEnabled">
                    <div class="col-md-12">
                      <textarea-input name="voiceRecordText" :label="fieldConfig.widgetSetting.voiceRecordText.label"
                        :max-length="fieldConfig.widgetSetting.voiceRecordText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceRecordText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--sms response title-->
                  <div class="row" v-if="smsEnabled">
                    <div class="col-md-6">
                      <textarea-input name="smsResponseTitleText"
                        :label="fieldConfig.widgetSetting.smsResponseTitleText.label" :max-length="fieldConfig.widgetSetting.smsResponseTitleText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.smsResponseTitleText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>

                    <!--sms response text-->
                    <div class="col-md-6">
                      <textarea-input name="smsResponseText" :label="fieldConfig.widgetSetting.smsResponseText.label"
                        :max-length="fieldConfig.widgetSetting.smsResponseText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.smsResponseText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--voice response text-->
                  <div class="row" v-if="callEnabled">
                    <div class="col-md-6">
                      <textarea-input name="voiceResponseTitleText"
                        :label="fieldConfig.widgetSetting.voiceResponseTitleText.label" :max-length="fieldConfig.widgetSetting.voiceResponseTitleText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceResponseTitleText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6"></div>

                    <div class="col-md-6">
                      <textarea-input name="voiceResponseText" :label="fieldConfig.widgetSetting.voiceResponseText.label"
                        :max-length="fieldConfig.widgetSetting.voiceResponseText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceResponseText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>

                    <!--scheduled voice response text-->
                    <div class="col-md-6">
                      <textarea-input name="scheduledVoiceResponseText"
                        :label="fieldConfig.widgetSetting.scheduledVoiceResponseText.label" :max-length="fieldConfig.widgetSetting.scheduledVoiceResponseText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.scheduledVoiceResponseText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--voice record response text-->
                  <div class="row" v-if="voiceRecordEnabled">
                    <div class="col-md-6">
                      <textarea-input name="voiceRecordResponseTitleText"
                        :label="fieldConfig.widgetSetting.voiceRecordResponseTitleText.label" :max-length="fieldConfig.widgetSetting.voiceRecordResponseTitleText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceRecordResponseTitleText" :required="true"
                        @focus="handleFocus" @blur="handleBlur" />
                    </div>
                    <div class="col-md-6">
                      <textarea-input name="voiceRecordResponseText"
                        :label="fieldConfig.widgetSetting.voiceRecordResponseText.label" :max-length="fieldConfig.widgetSetting.voiceRecordResponseText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceRecordResponseText" :required="true" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6" v-if="callEnabled">
                      <textarea-input name="buttonLabel" validate="required"
                        :label="fieldConfig.widgetSetting.buttonLabel.label" :max-length="fieldConfig.widgetSetting.buttonLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.buttonLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>

                    <div class="col-md-6" v-if="callEnabled">
                      <textarea-input name="scheduleButtonLabel" validate="required"
                        :label="fieldConfig.widgetSetting.scheduleButtonLabel.label" :max-length="fieldConfig.widgetSetting.scheduleButtonLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.scheduleButtonLabel" :required="true" :rows="1"
                        @focus="handleFocus" @blur="handleBlur" />
                    </div>

                    <!--sms button text-->
                    <div class="col-md-6" v-if="smsEnabled">
                      <textarea-input name="smsButtonLabel" validate="required"
                        :label="fieldConfig.widgetSetting.smsButtonLabel.label" :max-length="fieldConfig.widgetSetting.smsButtonLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.smsButtonLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6" v-if="voiceRecordEnabled">
                      <textarea-input name="voiceRecordButtonLabel" validate="required"
                        :label="fieldConfig.widgetSetting.voiceRecordButtonLabel.label" :max-length="fieldConfig.widgetSetting.voiceRecordButtonLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.voiceRecordButtonLabel" :required="true" :rows="1"
                        @focus="handleFocus" @blur="handleBlur" />
                    </div>
                    <div v-if="showBackButton" class="col-md-6">
                      <textarea-input name="backButtonLabel" validate="required"
                        :label="fieldConfig.widgetSetting.backButtonLabel.label" :max-length="fieldConfig.widgetSetting.backButtonLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.backButtonLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>

                    <!-- Form input labels-->
                    <div class="col-md-6">
                      <textarea-input name="mobileNumberLabel" validate="required"
                        :label="fieldConfig.widgetSetting.mobileNumberLabel.label" :max-length="fieldConfig.widgetSetting.mobileNumberLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.mobileNumberLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6" v-if="smsEnabled">
                      <textarea-input name="messageLabel" validate="required"
                        :label="fieldConfig.widgetSetting.messageLabel.label" :max-length="fieldConfig.widgetSetting.messageLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.messageLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6" v-if="callEnabled">
                      <textarea-input name="callScheduleLabel" validate="required"
                        :label="fieldConfig.widgetSetting.callScheduleLabel.label" :max-length="fieldConfig.widgetSetting.callScheduleLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.callScheduleLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6" v-if="callEnabled">
                      <textarea-input name="callLabel" validate="required"
                        :label="fieldConfig.widgetSetting.callLabel.label" :max-length="fieldConfig.widgetSetting.callLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.callLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                    <div class="col-md-6" v-if="showBrand">
                      <textarea-input name="poweredByLabel" validate="required"
                        :label="fieldConfig.widgetSetting.poweredByLabel.label" :max-length="fieldConfig.widgetSetting.poweredByLabel.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.poweredByLabel" :required="true" :rows="1" @focus="handleFocus"
                        @blur="handleBlur" />
                    </div>
                  </div>

                  <!--tooltip text-->
                  <div class="row" v-if="smsEnabled && !callEnabled">
                    <div class="col-md-12">
                      <textarea-input name="widgetTooltip" :label="fieldConfig.widgetSetting.tooltipText.label"
                        :max-length="fieldConfig.widgetSetting.tooltipText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.tooltipSmsText" :required="true" @focus="handleFocus" />
                    </div>
                  </div>

                  <!--tooltip text-->
                  <div class="row" v-else>
                    <div class="col-md-12">
                      <textarea-input name="widgetTooltip" :label="fieldConfig.widgetSetting.tooltipText.label"
                        :max-length="fieldConfig.widgetSetting.tooltipText.textLimit
                          " v-model="form.properties.languageList[mainLanguageIndex].value.tooltipText" :required="true" @focus="handleFocus" />
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="setting-mobile-button col-md-6">
                      <label class="lelabel">Enable Mobile Button</label>
                      <vuestic-switch v-model="form.properties.mobileButtonEnabled" class="align-middle" :offcolor="true"
                        @change="onEnableMobileButton">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>

                  <div class="row mt-5" v-if="form.properties.mobileButtonEnabled">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6" v-if="callEnabled">
                          <text-input name="widgetMobileButtonCall"
                            :label="fieldConfig.widgetSetting.mobileButtonCallLabel.label" :max-length="fieldConfig.widgetSetting.mobileButtonCallLabel.textLimit
                              " v-model="form.properties.mobileButtonCallLabel" :required="true"
                            @focus="handleFocus" />
                        </div>
                        <div class="col-md-6" v-if="smsEnabled">
                          <text-input name="widgetMobileButtonSms"
                            :label="fieldConfig.widgetSetting.mobileButtonSmsLabel.label" :max-length="fieldConfig.widgetSetting.mobileButtonSmsLabel.textLimit
                              " v-model="form.properties.mobileButtonSmsLabel" :required="true" @focus="handleFocus" />
                        </div>
                        <div class="col-md-12" v-if="smsEnabled">
                          <textarea-input name="widgetMobileSmsDefaultText"
                            :label="fieldConfig.widgetSetting.mobileSmsDefaultLabel.label" :max-length="fieldConfig.widgetSetting.mobileSmsDefaultLabel.textLimit
                              " v-model="form.properties.mobileSmsDefaultText"
                            :required="fieldConfig.widgetSetting.mobileSmsDefaultLabel.required"
                            :tooltip="fieldConfig.widgetSetting.mobileSmsDefaultLabel.tooltip" @focus="handleFocus" />
                        </div>
                      </div>
                    </div>
                    <div v-if="smsEnabled" class="col-md-6">
                      <label class="lelabel d-block w-100">
                        Mobile Button Secondary Color
                      </label>
                      <div class="widget-color-wrapper mt-3">
                        <div class="widget-color-selector-div">
                          <vuestic-color-picker-input class="pull-left rb-color-picker"
                            v-model="form.properties.widgetSecondaryColor" mode="advanced">
                            <vuestic-color-square :value="form.properties.widgetSecondaryColor" id="primaryColor" />
                            <text-input class="color-text d-block" name="Widget Color"
                              v-model="form.properties.widgetSecondaryColor" :disabled="true" />
                          </vuestic-color-picker-input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="setting-mobile-button col-md-6">
                      <label class="lelabel">Enable Default Countries <a v-b-tooltip.hover href="javascript:;"
                          title="List of countries that will be available for widget number. Turn this off and the widget will show all countries."
                          class="fa fa-question-circle tooltip-icon" /></label>
                      <vuestic-switch v-model="form.properties.enableDefaultCountry" class="align-middle"
                        :offcolor="true">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>

                  <div v-if="form.properties.enableDefaultCountry" class="row mt-4">
                    <div class="col-md-6">
                      <label class="lelabel">Countries</label>
                      <multiselect v-model="form.properties.listCountries" :options="listCountries" :multiple="true"
                        placeholder="Select countries" track-by="value" label="name">
                      </multiselect>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-6 col-sm-12" v-if="callEnabled">
                      <!--call scheduling-->
                      <div class="row mt-4">
                        <div class="col-md-12 col-sm-12 text-left">
                          <label class="lelabel">Enable Call Scheduling</label>
                          <vuestic-switch v-model="form.properties.callScheduling" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                      <div class="row mt-4" v-if="form.properties.callScheduling">
                        <div class="col-md-12">
                          <div class="checkbox abc-checkbox abc-checkbox-primary">
                            <input type="checkbox" name="name_required" id="callScheduleHrsOpen"
                              v-model="form.properties.callSchedulingHoursOpen" />
                            <label for="callScheduleHrsOpen">
                              <span class="abc-label-text">During Business Hours</span>
                            </label>
                          </div>
                          <div class="checkbox abc-checkbox abc-checkbox-primary">
                            <input type="checkbox" name="name_required" id="callScheduleHrsClose"
                              v-model="form.properties.callSchedulingHoursClose" />
                            <label for="callScheduleHrsClose">
                              <span class="abc-label-text">During Off Business Hours</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <!--contact button app-->
                      <div class="row mt-4">
                        <div class="col-md-12 col-sm-12">
                          <label class="lelabel">Show On Desktop</label>
                          <vuestic-switch v-model="form.properties.buttonApp" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">On</span>
                            <span slot="falseTitle">Off</span>
                          </vuestic-switch>
                        </div>
                      </div>

                      <!--mobile app-->
                      <div class="row mt-4">
                        <div class="col-md-12 col-sm-12">
                          <label class="lelabel">Show On Mobile</label>
                          <vuestic-switch v-model="form.properties.mobileApp" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">On</span>
                            <span slot="falseTitle">Off</span>
                          </vuestic-switch>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-md-12 col-sm-12">
                          <label class="lelabel">Show During Off Business Hours<a v-b-tooltip.hover
                              title="Widget will be shown during off business hours."
                              class="fa fa-question-circle tooltip-icon" /></label>
                          <vuestic-switch v-model="form.properties.showOffBizHrs" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">On</span>
                            <span slot="falseTitle">Off</span>
                          </vuestic-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right-wrapper" data-v-sticky-container>
                  <div v-sticky="stickyOption">
                    <div class="preview-wrapper" data-v-sticky-inner v-bind:class="{
                      left: form.properties.widgetPosition == 'left',
                      right: form.properties.widgetPosition == 'right',
                      center: form.properties.popupCenterEnabled,
                    }" :style="previewWrapperStyle">
                      <div class="preview-wrapper-dim"></div>
                      <div class="preview-main-view" v-if="previewMainViewType == 'main'">
                        <div class="widget-button-wrapper" :class="{ 'mb-3': form.properties.widgetIcon }"
                          :style="widgetButtonWrapper">
                          <button v-if="!form.properties.widgetIcon" class="widget-button active">
                            <img src="../../assets/images/widget/times.webp" alt="Phone Icon" />
                          </button>
                          <div v-else class="widget-button-custom">
                            <img :src="widgetIconImg" height="50" />
                          </div>
                        </div>
                        <div v-if="showTooltip" class="widget-tooltip-wrapper" :style="pWidget">
                          <span class="widget-tooltip-close">&#10005;</span>
                          <div class="widget-tooltip">
                            <div class="widget-tooltip-logo">
                              <div class="profile-img" :style="widgetProfileImg"></div>
                            </div>
                            <span>{{ widgetTooltipText }}</span>
                          </div>
                        </div>
                        <div v-else class="pwidget" :style="pWidget">
                          <div class="pwidget__header">
                            <div class="profile-img" :style="widgetProfileImg"></div>
                            <div style="flex: 1 1 auto">
                              <span class="title">{{ form.properties.languageList[mainLanguageIndex].value.headerTitle }}</span>
                            </div>
                            <div class=""></div>
                          </div>
                          <div class="pwidget__department-screen" v-if="widgetDepartmentShow &&
                            widgetCurrentScreen === 'department'
                            ">
                            <div class="pwidget__content">
                              <div>
                                <span class="title">{{
                                  form.properties.languageList[mainLanguageIndex].value.departmentText
                                }}</span>
                              </div>
                              <div class="inner-content-wrapper">
                                <ul class="method-select">
                                  <li v-for="(item, index) in form.departments" :key="index" @click="showGateScreen()">
                                    <span>{{ item.name }} </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div class="pwidget__gate-screen" v-if="widgetGateShow && widgetCurrentScreen === 'gate'
                            ">
                            <div class="pwidget__content">
                              <div>
                                <span class="title">{{
                                  form.properties.languageList[mainLanguageIndex].value.gateText
                                }}</span>
                              </div>
                              <div class="inner-content-wrapper">
                                <ul class="method-select">
                                  <li v-if="smsEnabled" @click="showMainScreen('sms')">
                                    <span class="gate-icon"><img
                                        src="../../assets/images/widget/text.webp" /></span><span>{{
                                          form.properties.languageList[mainLanguageIndex].value.smsConnectText }} </span>
                                  </li>
                                  <li v-if="callEnabled" @click="showMainScreen('call')">
                                    <span class="gate-icon"><img
                                        src="../../assets/images/widget/phone-icon.webp" /></span><span>{{
                                          form.properties.languageList[mainLanguageIndex].value.callConnectText }}
                                    </span>
                                  </li>
                                  <li v-if="voiceRecordEnabled" @click="showMainScreen('voice')">
                                    <span class="gate-icon"><img
                                        src="../../assets/images/widget/voicemail.webp" /></span><span>{{
                                          form.properties.languageList[mainLanguageIndex].value.recordConnectText }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="pwidget__footer" v-if="widgetDepartmentShow">
                              <div class="screen-button-wrapper">
                                <a href="javascript:;" @click="backToMainScreen()"
                                  class="screen-button screen-button-back">
                                  <img src="../../assets/images/back.webp" alt="Back Icon" />
                                  {{ form.properties.languageList[mainLanguageIndex].value.backButtonLabel }}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="pwidget__main-screen" v-if="widgetCurrentScreen === 'response'
                            ">
                            <div class="pwidget__content">
                              <div class="message-box from">
                                <div class="message-box__inner">
                                  <span>{{ previewHeadText }}</span>
                                </div>
                              </div>
                              <div class="message-box to">
                                <div class="message-box__inner">
                                  <span>(333)-333-3333<br />{{ widgetResponseMessage }}</span>
                                </div>
                              </div>
                              <div class="message-box reply">
                                <div class="message-box__inner">
                                  <div class="reply-title">{{ businessName }}</div>
                                  <div class="reply-body">
                                    <div class="reply-body-title">{{ widgetResponseTitle }}</div>
                                    <div class="reply-body-content">{{ widgetResponseText }}</div>
                                    <div class="reply-number">(333)-333-3333</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="pwidget__footer">
                              <div class="screen-button-wrapper">
                                <a href="javascript:;" @click="backToMainScreen()"
                                  class="screen-button screen-button-back">
                                  <img src="../../assets/images/back.webp" alt="Back Icon" />
                                  {{ form.properties.backButtonLabel }}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="pwidget__main-screen" v-if="widgetCurrentScreen === 'main'">
                            <div class="pwidget__content">
                              <div class="message-box from">
                                <div class="message-box__inner">
                                  <span>{{ previewHeadText }}</span>
                                </div>
                              </div>
                              <div class="message-box to action-wrapper">
                                <div class="message-box__inner">
                                  <div class="inner-content-wrapper">
                                    <div v-if="form.properties.widgetNameEnabled" class="material-group"
                                      style="margin-top: 10px">
                                      <input class="material-input" placeholder="" type="text" required />
                                      <span class="material-highlight"></span>
                                      <span class="material-bar"></span>
                                      <label class="material-label">{{ form.properties.languageList[mainLanguageIndex].value.nameLabel }}<span v-if="form.properties.widgetNameRequired">*</span></label>
                                    </div>
                                    <div v-if="form.properties.widgetEmailEnabled" class="material-group"
                                      style="margin-top: 10px">
                                      <input class="material-input" placeholder="" type="text" required />
                                      <span class="material-highlight"></span>
                                      <span class="material-bar"></span>
                                      <label class="material-label">{{ form.properties.languageList[mainLanguageIndex].value.emailLabel }}<span v-if="form.properties.widgetEmailRequired">*</span></label>
                                    </div>
                                    <PhoneNumberInputRaw :label="form.properties.languageList[mainLanguageIndex].value.mobileNumberLabel" />
                                    <div class="pwidget-datetime-wrapper" v-if="this.previewMode == 'schedule' &&
                                      form.properties.callScheduling
                                      ">
                                      <div class="pwidget-form-input-group">
                                        <div class="pwidget-dropdown pwidget-form-input-schedule-date">
                                          <div class="pwidget-dropdown-label">
                                            <span class="pwidget-icon pwidget-icon-calendar">
                                              <svg aria-hidden="true" focusable="false" data-prefix="far"
                                                data-icon="calendar-alt" class="svg-inline--fa fa-calendar-alt fa-w-14"
                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path fill="#fff"
                                                  d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z">
                                                </path>
                                              </svg>
                                            </span>
                                            <span class="pwidget-dropdown-placeholder">Date</span>
                                          </div>
                                          <ul class="pwidget-dropdown-inner"></ul>
                                        </div>
                                      </div>
                                      <div class="pwidget-form-input-group">
                                        <div class="pwidget-dropdown pwidget-form-input-schedule-time">
                                          <div class="pwidget-dropdown-label">
                                            <span class="pwidget-icon pwidget-icon-clock">
                                              <svg aria-hidden="true" focusable="false" data-prefix="far"
                                                data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="#fff"
                                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z">
                                                </path>
                                              </svg>
                                            </span>
                                            <span class="pwidget-dropdown-placeholder">Time</span>
                                          </div>
                                          <ul class="pwidget-dropdown-inner"></ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="pwidget-message-wrapper" v-if="this.previewMode == 'sms'">
                                      <div class="material-group">
                                        <textarea maxlength="320" rows="2" id="form-input-message"
                                          class="material-input form-input-message" required></textarea>
                                        <span class="material-highlight"></span>
                                        <span class="material-bar"></span>
                                        <label class="material-label">{{ form.properties.languageList[mainLanguageIndex].value.messageLabel
                                        }}<span>*</span></label>
                                      </div>
                                    </div>
                                    <div v-for="(customField, index) in contactFields" :key="`customField-${index}`">
                                      <div v-if="form.properties.customFields[customField.id + 'IsEnable']" class="material-group"
                                        style="margin-top: 10px">
                                        <input class="material-input" placeholder="" type="text" required />
                                        <span class="material-highlight"></span>
                                        <span class="material-bar"></span>
                                        <label class="material-label">{{ form.properties.customFields[customField.id + 'Label'] || customField.name }}<span v-if="form.properties.customFields[customField.id + 'IsRequired']">*</span></label>
                                      </div>
                                    </div>
                                    
                                    <div class="pwidget-record-wrapper" v-if="this.previewMode == 'voice'">
                                      <a href="javascript:;" class="record-icon">
                                        <img src="../../assets/images/widget/microphone.webp" alt="Record Icon" />
                                      </a>
                                    </div>
                                    <div class="actions" v-if="form.properties.callScheduling &&
                                      ['call', 'schedule'].includes(previewMode)
                                      ">
                                      <a href="javascript:;" v-if="previewMode != 'call'"
                                        @click="toggleWidgetView('call')" class="link action-link">{{
                                          form.properties.languageList[mainLanguageIndex].value.callLabel }}</a>
                                      <a href="javascript:;" v-if="previewMode != 'schedule'"
                                        @click="toggleWidgetView('schedule')" class="link action-link">{{
                                          form.properties.languageList[mainLanguageIndex].value.callScheduleLabel }}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="gdpr-wrapper" v-if="this.previewMode != 'sms' && this.previewMode != 'voice'"
                                v-bind:class="{
                                  'd-block': !form.properties.gdprEnabled,
                                }">
                                <div class="custom-checkbox-wrapper" v-if="form.properties.gdprEnabled">
                                  <label class="checkbox-label">
                                    <input v-model="gdprChecked" type="checkbox" id="gdpr-checkbox" />
                                    <span class="checkbox-custom"></span>
                                  </label>
                                </div>
                                <p class="label" @click="gdprChecked = !gdprChecked">{{ form.properties.languageList[mainLanguageIndex].value.gdprText }}</p>
                              </div>
                              <div class="gdpr-wrapper" v-else v-bind:class="{
                                'd-block': !form.properties.gdprSmsEnabled,
                              }">
                                <div class="custom-checkbox-wrapper" v-if="form.properties.gdprSmsEnabled">
                                  <label class="checkbox-label">
                                    <input v-model="gdprSmsChecked" type="checkbox" id="gdpr-checkbox" />
                                    <span class="checkbox-custom"></span>
                                  </label>
                                </div>
                                <p class="label" @click="gdprSmsChecked = !gdprSmsChecked">{{ form.properties.languageList[mainLanguageIndex].value.gdprSmsText }}</p>
                              </div>

                              <div class="gdpr-wrapper promotion-wrapper" v-bind:class="{
                                'd-block': !form.properties.promotionEnabled,
                              }">
                                <div class="custom-checkbox-wrapper" v-if="form.properties.promotionEnabled">
                                  <label class="checkbox-label">
                                    <input v-model="promotionChecked" type="checkbox" id="gdpr-checkbox" />
                                    <span class="checkbox-custom"></span>
                                  </label>
                                </div>
                                <p class="label" @click="promotionChecked = !promotionChecked">{{ form.properties.languageList[mainLanguageIndex].value.promotionText }}</p>
                              </div>

                              <div class="action-button-wrapper d-flex justify-content-center">
                                <button class="action-button">
                                  {{ widgetButtonLabel }}
                                </button>
                              </div>
                            </div>
                            <div class="pwidget__footer">
                              <div class="screen-button-wrapper" v-if="widgetDepartmentShow || widgetGateShow">
                                <a href="javascript:;" @click="backToMainScreen()"
                                  class="screen-button screen-button-back">
                                  <img src="../../assets/images/back.webp" alt="Back Icon" />
                                  {{ form.properties.languageList[mainLanguageIndex].value.backButtonLabel }}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="pwidget__main_footer" v-if="showBrand">
                            <div class="powered_by" v-if="poweredBy.url">
                              {{ form.properties.languageList[mainLanguageIndex].value.poweredByLabel }} <a :href="poweredBy.url" target="_blank">{{
                                poweredBy.name }}</a>
                            </div>
                            <div class="powered_by" v-else>
                              {{ form.properties.languageList[mainLanguageIndex].value.poweredByLabel }} {{ poweredBy.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="preview-mobile-button-view" v-if="previewMainViewType == 'mobile-button'">
                        <a href="javascript:;" class="mobile-button-item call-btn" v-if="callEnabled">{{
                          form.properties.mobileButtonCallLabel }}</a>
                        <a href="javascript:;" class="mobile-button-item sms-btn" v-if="smsEnabled">{{
                          form.properties.mobileButtonSmsLabel }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5">
              <!-- <b-button class="m-1">Advanced</b-button> -->
              <button v-b-toggle.advanced-collapsed class="btn-le btn-primary btn-sm align-middle px-5">
                Advanced
              </button>

              <b-collapse id="advanced-collapsed">
                <div class="row mt-4">
                  <div class="col-md-3 col-sm-12">
                    <!-- exit intent -->
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Pop-Up on Exit Intent</label>
                        <vuestic-switch v-model="form.properties.widgetExitIntentPopUpEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Show Name Field On Widget
                          <a v-b-tooltip.hover :title="showNameTooltip" class="fa fa-question-circle tooltip-icon" />
                        </label>
                        <vuestic-switch v-model="form.properties.widgetNameEnabled" class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div v-if="form.properties.widgetNameEnabled" class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="name_required" :id="`name-required`"
                            v-model="form.properties.widgetNameRequired" />
                          <label :for="`name-required`">
                            <span class="abc-label-text">Is Required</span>
                          </label>
                        </div>
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="name_validation" :id="`name-validation`"
                            v-model="form.properties.widgetNameValidationEnabled" />
                          <label :for="`name-validation`">
                            <span class="abc-label-text">Enable Validation</span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <textarea-input name="nameLabel" validate="required"
                          :label="fieldConfig.widgetSetting.nameLabel.label" :max-length="fieldConfig.widgetSetting.nameLabel.textLimit
                            " v-model="form.properties.languageList[mainLanguageIndex].value.nameLabel" :required="true" :rows="1" @focus="handleFocus"
                          @blur="handleBlur" />
                      </div>
                    </div>
                    <!-- show email -->
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Show Email Field On Widget
                          <a v-b-tooltip.hover :title="showEmailTooltip" class="fa fa-question-circle tooltip-icon" />
                        </label>
                        <vuestic-switch v-model="form.properties.widgetEmailEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div v-if="form.properties.widgetEmailEnabled" class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="schedule" :id="`email-required`"
                            v-model="form.properties.widgetEmailRequired" />
                          <label :for="`email-required`">
                            <span class="abc-label-text">Is Required</span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <textarea-input name="emailLabel" validate="required"
                          :label="fieldConfig.widgetSetting.emailLabel.label" :max-length="fieldConfig.widgetSetting.emailLabel.textLimit
                            " v-model="form.properties.languageList[mainLanguageIndex].value.emailLabel" :required="true" :rows="1" @focus="handleFocus"
                          @blur="handleBlur" />
                      </div>
                    </div>
                    <!-- show custom fields -->
                    <div v-for="(customField, index) in contactFields" :key="`customField-${index}`">
                      <div class="row mt-4">
                        <div class="col-md-12 col-sm-12 text-left">
                          <label class="lelabel">Show {{ customField.name }} Field On Widget
                          </label>
                          <vuestic-switch v-model="form.properties.customFields[customField.id + 'IsEnable']" class="align-middle"
                            :offcolor="true">
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                      <div v-if="form.properties.customFields[customField.id + 'IsEnable']" class="row mt-4">
                        <div class="col-md-12 col-sm-12 text-left">
                          <div class="checkbox abc-checkbox abc-checkbox-primary">
                            <input type="checkbox" name="schedule" :id="`${customField.id}-required`"
                              v-model="form.properties.customFields[customField.id + 'IsRequired']" />
                            <label :for="`${customField.id}-required`">
                              <span class="abc-label-text">Is Required</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <textarea-input name="emailLabel" validate="required"
                            :label="fieldConfig.widgetSetting.emailLabel.label" :max-length="fieldConfig.widgetSetting.emailLabel.textLimit
                              " v-model="form.properties.customFields[customField.id + 'Label']" :required="true" :rows="1" @focus="handleFocus"
                            @blur="handleBlur" />
                        </div>
                      </div>
                    </div>
                    <!-- exit intent pop-up : only once -->
                    <div class="row mt-4" v-if="form.properties.widgetExitIntentPopUpEnabled">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Pop-Up on Exit Intent Only Once</label>
                        <vuestic-switch v-model="form.properties.widgetExitIntentPopUpOnlyOnce
                          " class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Show tool tip</label>
                        <vuestic-switch v-model="form.properties.widgetTooltipEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>

                    <!--auto pop-up [Yes/No]-->
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Auto Pop-Up</label>
                        <vuestic-switch v-model="form.properties.widgetAutoPopUpEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>

                    <!--auto pop-up delay [sec]-->
                    <div class="row mt-4" v-if="form.properties.widgetAutoPopUpEnabled">
                      <div class="col-md-12 col-sm-12 text-left selec-div">
                        <div class="form-group">
                          <div class="input-group">
                            <select v-model="form.properties.widgetAutoPopUpDelay" class="form-control">
                              <option v-for="(value, index) in autoPopupValues" :key="index" :value="value.key">
                                {{ value.value }}
                              </option>
                            </select>
                            <label class="control-label">Auto Pop-Up Delay [sec]</label>
                            <i class="bar"></i>
                            <i class="ion ion-ios-arrow-down icon-right input-icon custom-input-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--dim page on pop-up [Yes/No]-->
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Dim page on Pop-Up</label>
                        <vuestic-switch v-model="form.properties.widgetDimPageOnPopUpEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>

                    <!--dim overlay color [#HEX]-->
                    <div class="row mt-4" v-if="form.properties.widgetDimPageOnPopUpEnabled">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel" for="widgetDimOverlayColor">Dim overlay Color</label>
                        <div class="widget-color-wrapper mt-1">
                          <vuestic-color-picker-input class="d-block rb-color-picker"
                            v-model="form.properties.widgetDimOverlayColor" mode="advanced">
                            <vuestic-color-square :value="form.properties.widgetDimOverlayColor"
                              id="widgetDimOverlayColor" />
                            <text-input class="color-text d-block" name="Dim Color"
                              v-model="form.properties.widgetDimOverlayColor" :disabled="true" />
                          </vuestic-color-picker-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="row mt-4">
                      <div class="col-md-12 col-sm-12 text-left">
                        <label class="lelabel">Call Recording</label>
                        <vuestic-switch v-model="form.call_recording" class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="callEnabled">
                      <div class="col-md-12 col-sm-12 text-left selec-div">
                        <div class="form-group" style="margin-top: 14px">
                          <div class="input-group">
                            <select v-model="form.retry_times" class="form-control">
                              <option v-for="(value, index) in numberOfRetries" :key="index" :value="value.key">
                                {{ value.value }}
                              </option>
                            </select>
                            <label class="control-label">Number of Retries</label>
                            <i class="bar"></i>
                            <i class="ion ion-ios-arrow-down icon-right input-icon custom-input-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="callEnabled">
                      <div class="col-md-12 col-sm-12 text-left selec-div">
                        <div class="form-group">
                          <div class="input-group">
                            <select v-model="form.retry_delay" class="form-control">
                              <option v-for="(value, index) in retryDelayValues" :key="index" :value="value">
                                {{ value }}
                              </option>
                            </select>
                            <label class="control-label">Number of Minutes Between Retries</label>
                            <i class="bar"></i>
                            <i class="ion ion-ios-arrow-down icon-right input-icon custom-input-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="callEnabled">
                      <div class="col-md-12 col-sm-12 text-left selec-div">
                        <div class="form-group">
                          <div class="input-group">
                            <select v-model="form.properties.timeout" class="form-control">
                              <option v-for="(
                                    value, index
                                  ) in connectionTimeoutValues" :key="index" :value="value">
                                {{ value }}
                              </option>
                            </select>
                            <label class="control-label">Connection Timeout [SEC]</label>
                            <i class="bar"></i>
                            <i class="ion ion-ios-arrow-down icon-right input-icon custom-input-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <label class="h5 mt-4">GDPR Setting</label>
                  <div class="row">
                    <div class="col-md-6" v-if="callEnabled">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <label class="lelabel">GDPR/Consent Check Box
                            <i v-if="smsEnabled"><b>( Phone )</b></i></label>
                          <vuestic-switch v-model="form.properties.gdprEnabled" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12">
                          <textarea-input name="gdprText" :label="fieldConfig.widgetSetting.gdprText.label" :max-length="fieldConfig.widgetSetting.gdprText.textLimit
                            " v-model="form.properties.languageList[mainLanguageIndex].value.gdprText" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="smsEnabled">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <label class="lelabel">GDPR/Consent Check Box
                            <i v-if="callEnabled"><b>( Text )</b></i></label>
                          <vuestic-switch v-model="form.properties.gdprSmsEnabled" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12">
                          <textarea-input name="gdprSmsText" :label="fieldConfig.widgetSetting.gdprSmsText.label"
                            :max-length="fieldConfig.widgetSetting.gdprSmsText.textLimit
                              " v-model="form.properties.languageList[mainLanguageIndex].value.gdprSmsText" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <label class="lelabel">Promotion Checkbox</label>
                          <vuestic-switch v-model="form.properties.promotionEnabled" class="align-middle" :offcolor="true">
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12">
                          <div v-if="form.properties.promotionEnabled" class="checkbox abc-checkbox abc-checkbox-primary">
                            <input type="checkbox" name="name_required" id="promotion-check-required"
                              v-model="form.properties.promotionCheckRequired" />
                            <label for="promotion-check-required">
                              <span class="abc-label-text">Is Required</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12">
                          <textarea-input name="promotionText" :label="fieldConfig.widgetSetting.promotionText.label"
                            :max-length="fieldConfig.widgetSetting.promotionText.textLimit
                              " v-model="form.properties.languageList[mainLanguageIndex].value.promotionText" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="smsEnabled" class="col-md-6">
                    <label class="h5 mt-4">Auto Responder
                      <span v-if="callEnabled"><small>( Text )</small></span><a v-b-tooltip.hover
                        title="This reply will be sent out when there is a sms requst from widget during business hours."
                        class="fa fa-question-circle tooltip-icon" /></label>
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <label class="lelabel">Auto Responder Text</label>
                        <vuestic-switch v-model="form.properties.autoResponderEnabled" class="align-middle"
                          :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div v-if="form.properties.autoResponderEnabled" class="row mt-4">
                      <div class="col-md-12">
                        <div>
                          <label class="lelabel">{{ fieldConfig.widgetSetting.autoResponderText.label }}</label>
                        </div>
                        <TextareaEmojiPicker v-model="form.properties.languageList[mainLanguageIndex].value.autoResponderText" :focused="true" :rows="3"
                          :enabledMms="true" ref="textareaEmoji"
                          :maxLength="fieldConfig.widgetSetting.autoResponderText.textLimit - optText.length"
                          :showSendButton="false" :hasHoverEffect="false" :optText="optText"
                          :images="form.properties.autoResponderTextImgs" @done="handleResponderTextUploadedImage" />
                      </div>
                    </div>
                  </div>
                  <div v-if="callEnabled" class="col-md-6">
                    <label class="h5 mt-4">Auto Responder
                      <span><small>( Missed Call )</small></span><a v-b-tooltip.hover
                        title="This reply will be sent out when there is a missed call from widget."
                        class="fa fa-question-circle tooltip-icon" /></label>
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <label class="lelabel">Auto Responder Missed Call</label>
                        <vuestic-switch v-model="form.properties.autoResponderMissedCallEnabled
                          " class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div v-if="form.properties.autoResponderMissedCallEnabled" class="row mt-4">
                      <div class="col-md-12">
                        <div>
                          <label class="lelabel">{{ fieldConfig.widgetSetting.autoResponderMissedCall.label }}</label>
                        </div>
                        <TextareaEmojiPicker v-model="form.properties.languageList[mainLanguageIndex].value.autoResponderMissedCallText" :focused="true"
                          :rows="3" :enabledMms="true" ref="textareaEmoji"
                          :maxLength="fieldConfig.widgetSetting.autoResponderMissedCall.textLimit - optText.length"
                          :showSendButton="false" :hasHoverEffect="false" :optText="optText"
                          :images="form.properties.autoResponderMissedCallImgs"
                          @done="handleResponderCallUploadedImage" />
                      </div>
                    </div>
                  </div>
                  <div v-if="callEnabled" class="col-md-6">
                    <label class="h5 mt-5">Auto Responder
                      <span><small>( Scheduled Call )</small></span><a v-b-tooltip.hover
                        title="This reply will be sent out when there is a scheduled call from widget."
                        class="fa fa-question-circle tooltip-icon" /></label>
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <label class="lelabel">Auto Responder Scheduled Call</label>
                        <vuestic-switch v-model="form.properties.autoResponderScheduledCallEnabled
                          " class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                    <div v-if="form.properties.autoResponderScheduledCallEnabled" class="row mt-4">
                      <div class="col-md-12">
                        <div>
                          <label class="lelabel">{{ fieldConfig.widgetSetting.autoResponderScheduledCall.label }}</label>
                        </div>
                        <TextareaEmojiPicker v-model="form.properties.languageList[mainLanguageIndex].value.autoResponderScheduledCallText" :focused="true"
                          :rows="3" :enabledMms="true" ref="textareaEmoji"
                          :maxLength="fieldConfig.widgetSetting.autoResponderScheduledCall.textLimit - optText.length"
                          :showSendButton="false" :hasHoverEffect="false" :optText="optText"
                          :images="form.properties.autoResponderScheduledCallImgs"
                          @done="handleResponderCallUploadedImage" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 mt-4">
                    <label class="h5 d-block mt-4">Excluded URLs</label>
                    <div class="row mt-4">
                      <div class="col-md-4">
                        <label class="lelabel">Exclude Urls</label>
                        <vuestic-switch v-model="form.properties.isExcludeUrls" class="align-middle" :offcolor="true">
                          <span slot="trueTitle">Yes</span>
                          <span slot="falseTitle">No</span>
                        </vuestic-switch>
                      </div>
                    </div>
                  </div>
                  <div v-if="form.properties.isExcludeUrls" class="col-md-12 mt-4">
                    <p>By default, widget appears on all pages but you can choose to exclude one or more URLs. If a URL is
                      exluded, the widget will not appear on this page.</p>
                    <button type="button" class="btn btn-primary btn-sm" @click="onAddExcludeUrls">
                      Add URL
                    </button>

                    <div class="row mt-4" v-if="form.properties.excludeUrls">
                      <div class="col-md-6">
                        <table class="table table-agents">
                          <tbody>
                            <tr v-for="(item, index) in form.properties.excludeUrls" :key="index">
                              <td width="45%">
                                {{ item.type }}
                              </td>
                              <td width="45%">
                                {{ item.regex }}
                              </td>
                              <td width="10%">
                                <button type="button" class="btn btn-danger btn-sm" @click="onDeleteExcludeUrl(index)">
                                  <i class="fa fa-trash-o" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="h5 d-block mt-4">Redirect URL</label>
                        <div class="row mt-4">
                          <div class="col-md-12">
                            <label class="lelabel">Redirect URL</label>
                            <vuestic-switch v-model="form.properties.isRedirectUrl" class="align-middle" :offcolor="true">
                              <span slot="trueTitle">Yes</span>
                              <span slot="falseTitle">No</span>
                            </vuestic-switch>
                          </div>
                        </div>
                      </div>
                      <div v-if="form.properties.isRedirectUrl" class="col-md-12 mt-4">
                        <text-input name="url" validate="required" v-model="form.properties.redirectUrl"
                          label="Redirect URL" tooltip="We will send the contact to this url after wigdet request." />
                      </div>
                    </div>
                  </div>
                  <template v-if="callEnabled && formEnabled">
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="h5 d-block mt-4">Form Integration</label>
                      </div>
                      <label class="lelabel mt-2">Enable to monitor forms</label>
                      <vuestic-switch v-model="form.properties.formIntegration" class="align-middle" :offcolor="true">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </template>
                  <template v-if="brandRemoveEnabled">
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="h5 d-block mt-4">Remove Brand</label>
                      </div>
                      <label class="lelabel mt-2">Remove Default Brand from Widget</label>
                      <vuestic-switch v-model="form.properties.removeBranding" class="align-middle" :offcolor="true">
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </template>
                  <div v-if="!callEnabled || !smsEnabled" class="col-md-4">
                    <div class="mb-4">
                      <label class="h5 d-block mt-4">Direct Request</label>
                    </div>
                    <label class="lelabel mt-2">Enable to handle direct {{ smsEnabled ? 'call' : 'sms' }} request</label>
                    <vuestic-switch v-model="form.properties.directRequest" class="align-middle" :offcolor="true">
                      <span slot="trueTitle">Yes</span>
                      <span slot="falseTitle">No</span>
                    </vuestic-switch>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div slot="code" class="form-wizard-tab-content">
          <h6>Copy the widget code and install it on Your website</h6>
          <p>
            Copy the Script below and paste it right before the closing
            <strong class="le-blue">&lt;/body&gt;</strong> tag or
            <strong class="le-blue">&lt;footer&gt;</strong> of your website code
          </p>
          <textarea class="code" rows="4" id="embcod" readonly>{{
            embedCode
          }}</textarea>
          <div class="col-12 ml-auto mr-0 pr-0 text-right">
            <button type="button" class="btn btn-primary" v-clipboard="embedCode" v-clipboard:success="onCopy"
              v-clipboard:error="onError">
              Copy the code
            </button>
          </div>
        </div>
        <div slot="wizardCompleted" class="form-wizard-tab-content">
          <h4>{{ "forms.wizard.completed" | translate }}</h4>
          <p>All Done</p>
        </div>
      </vuestic-wizard>
      <vuestic-modal :isOpen="isVerifyModalOpen" okText="Confirm" cancelText="Close" :cancelShown="false"
        :closeOnOk="false" :okDisabled="!phoneVerifyCode" @ok="onVerifyOk" @cancel="closeVerifyModal">
        <span slot="title">Set Caller</span>
        <div class="verify-modal-body">
          <vuestic-widget :loading="isVerifyLoading">
            <p>
              We will call your default business number to verify phone
              number.<br />Please click button below to get a call.
            </p>
            <button class="btn btn-primary btn-sm align-middle d-block ml-auto mr-auto" @click="verifyPhonNumber()">
              Call Me Now
            </button>
            <p class="pt-3">
              Please click Call Me Now button again if you don't get a call, We
              will initiate another call with new verification code
            </p>
            <div v-if="phoneVerifyCode" class="py-2">
              <p class="d-block m-auto pb-2">
                <strong>Verification Code</strong>
              </p>
              <pre class="pre-code">{{ phoneVerifyCode }}</pre>
            </div>
            <p>Please click on confirm once you verify your phone number.</p>
          </vuestic-widget>
        </div>
      </vuestic-modal>
      <vuestic-modal :isOpen="isLandlineModalOpen" :cancelShown="false" :okShown="false" :closeOnOk="false"
        @cancel="isLandlineModalOpen = false">
        <span slot="title">Assign Your Landline Number</span>
        <div class="landline-modal">
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
            <form @submit.prevent="handleSubmit(handleAssignLandline)">
              <div class="form-group twilio-phone form-group-w-btn">
                <div class="input-group has-margin-top">
                  <phone-number-input name="Landline Number" v-model="form.landline_number" placeholder="Landline Number"
                    :allowValidation="true" :disabled="processingLandline" />
                  <label class="control-label">Landline Number
                    <a v-b-tooltip.hover title="Landline Number" class="fa fa-question-circle tooltip-icon" />
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center" v-if="errorMessageLandline">
                  <b-alert class="err-message text-center" show variant="danger" v-html="errorMessageLandline"></b-alert>
                  <span v-if="forceButton" @click="handleAssignLandline(true)" class="btn btn-sm btn-primary"
                    :disabled="invalid || processingLandline" style="min-width: 80px">
                    <atom-spinner v-if="processingLandline" slot="processingLandline" :animation-duration="1500"
                      :size="14" color="#FFF" style="margin: auto" />
                    <div v-else class="d-flex justify-content-center">
                      <span>Continue</span>
                      <label class="lelabel">
                        <a v-b-tooltip.hover title="We will deactivate this landline number from other installs."
                          class="fa fa-question-circle tooltip-icon text-white button-tooltip" />
                      </label>
                    </div>
                  </span>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12 d-flex justify-content-center">
                  <button class="btn btn-primary" :disabled="invalid || processingLandline" style="min-width: 80px">
                    <atom-spinner v-if="processingLandline" slot="processingLandline" :animation-duration="1500"
                      :size="14" color="#FFF" style="margin: auto" />
                    <span v-else>Assign</span>
                  </button>
                  <button type="button" class="btn btn-primary ml-2" @click="closeLandlineModal">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </vuestic-modal>
      <vuestic-modal :isOpen="isOpenModalDisableLandline" @ok="handleDisableLandline"
        @cancel="isOpenModalDisableLandline = false" okText="Disable" cancelText="Cancel" :closeOnOk="false"
        :processing="processingLandline" okClass="btn btn-danger btn-primary">
        <span slot="title" class="text-danger font-weight-bold">Disable Landline Number</span>
        <div>
          Confirm you want to disable this Landline Number
          <b class="text-primary">{{ form.landline_number }}</b>?
        </div>
      </vuestic-modal>
    </vuestic-widget>

    <vuestic-modal :isOpen="isOpenModalDepartment" @ok="handleAddDepartment" @cancel="closeDepartmentModal" okText="Add"
      :okDisabled="!newDepartment" cancelText="Cancel" :closeOnOk="false">
      <span slot="title" class="text-primary"><b>New Department</b></span>
      <div class="row">
        <div class="col-md-12">
          <span class="">To add additional departments you must first create them, click
            <router-link class="text-primary font-weight-bold"
              :to="{ name: 'business.departments.index' }">HERE</router-link>
            to add additional departments.</span>
        </div>
        <div class="col-md-12 mt-3">
          <multiselect :show-labels="false" v-model="newDepartment" :options="departmentList" :multiple="false"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Department"
            label="label" track-by="label" :preselect-first="true" :allowEmpty="false">
          </multiselect>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalAgent" @ok="handleAddAgent" @cancel="closeAgentModal" okText="Add"
      :okDisabled="!newAgent" cancelText="Cancel" :closeOnOk="false">
      <span slot="title" class="text-primary"><b>New Agent</b></span>
      <div class="row">
        <div class="col-md-12">
          <span class="">To add additional agents you must first create them as users, click
            <router-link class="text-primary font-weight-bold" :to="{ name: 'business.users.index' }">HERE</router-link>
            to add additional users.</span>
        </div>
        <div class="col-md-12 mt-3">
          <multiselect :show-labels="false" v-model="newAgent" :options="userList" :multiple="false"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Agent"
            label="label" track-by="label" :preselect-first="true" :allowEmpty="false">
          </multiselect>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalExcludeUrls" @ok="handleAddExcludeUrls" @cancel="onCloseModalExcludeUrls"
      okText="Add Rule" :okDisabled="!newExcludeUrl.regex" cancelText="Cancel" :closeOnOk="false">
      <span slot="title" class="text-primary"><b>Add Excluded URL</b></span>
      <div class="row">
        <div class="col-md-12">
          <label>Rule</label>
          <multiselect :show-labels="false" v-model="newExcludeUrl.type"
            :options="['Contain', 'End with', 'Start with', 'Is exactly', 'Match regex']" :multiple="false"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" :preselect-first="true"
            :allowEmpty="false">
          </multiselect>
        </div>
        <div class="col-md-12 mt-3">
          <text-input name="regex" v-model="newExcludeUrl.regex" label="URL TO EXCLUDE" placeholder="(e.g. /terms)" />
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalLanguage" @cancel="closeModalLanguage" :cancelShown="false" :okShown="false">
      <span slot="title">Add Language</span>
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-3">You can select language here</h6>
          <label class="field-label">Language</label>
          <multiselect class="filter-select d-inline-block" v-model="selectedLanguage" :options="allLanguagesList"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Language"
            label="label" track-by="id" :preselect-first="true" :show-labels="false" :searchable="true" :allowEmpty="false">
          </multiselect>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="addInstallLanguage" class="btn btn-primary" style="min-width: 80px;">
            <span>Add</span>
          </button>
          <button type="button" @click="closeModalLanguage" class="btn btn-outline ml-2">Close</button>
        </div>
      </div>
    </vuestic-modal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import timezones from "timezones.json";
import moment from "moment-timezone";
import TwilioNumberSelector from "../common/TwilioNumberSelector";
import TextareaEmojiPicker from "../common/TextareaEmojiPicker";
import merge from "lodash/merge";
import TimeSelector from "../forms/TimeSelector";
import VuesticFileUpload from "../../vuestic-theme/vuestic-components/vuestic-file-upload/VuesticFileUpload";
import VueStickyDirective from "@renatodeleao/vue-sticky-directive";
import {
  parsePhoneNumber,
} from "libphonenumber-js";

import VuesticColorPickerInput from "../../vuestic-theme/vuestic-components/vuestic-color-picker/VuesticColorPickerInput";
import VuesticColorSquare from "../../vuestic-theme/vuestic-components/vuestic-color-picker/VuesticColorSquare";
import VuesticColorInput from "../../vuestic-theme/vuestic-components/vuestic-color-picker/VuesticColorInput";
import utils from "../../services/utils";
import cloneDeep from "clone-deep";
import { defaultInstallData } from "../../constants";
import { languageList } from "../../constants/language";
import PhoneNumberInputRaw from "../forms/PhoneNumberInputRaw";
const { getCodeList } = require('country-list');
import TagSelector from '../common/TagSelector'

export default {
  components: {
    VuesticFileUpload,
    TwilioNumberSelector,
    TimeSelector,
    TextareaEmojiPicker,
    VuesticColorPickerInput,
    VuesticColorSquare,
    VuesticColorInput,
    PhoneNumberInputRaw,
    TagSelector,
  },

  directives: {
    sticky: VueStickyDirective,
  },

  data() {
    return {
      allLanguagesList: languageList,
      isOpenModalLanguage: false,
      selectedLanguage: "",
      mainLanguageList: [],
      mainLanguageIndex: 0,
      selectedMainLanguage : {"id": "en", "label": "English"},
      twilioStatus: undefined,
      previewMainViewType: 'main',
      gdprChecked: false,
      gdprSmsChecked: false,
      promotionChecked: false,
      showTooltip: false,
      widgetCurrentScreen: undefined,
      widgetDepartmentShow: false,
      widgetGateShow: false,
      addType: undefined,
      newAgent: undefined,
      isOpenModalAgent: false,
      newDepartment: undefined,
      isOpenModalDepartment: false,
      smsAlertMessage: "SMS feature won't be available for this number.",
      callAlertMessage: "CALL feature won't be available for this number.",
      showNameTooltip:
        "When enable name field you will be notified of the person name when they request a call and the name will be stored in the database.",
      showEmailTooltip:
        "When enable email field you will be notified of the person email when they request a call and the email will be stored in the database.",
      stickyOption: {
        topSpacing: 100,
      },
      isFetched: false,
      isVerifyModalOpen: false,
      isVerifyLoading: false,
      phoneVerifyCode: "",
      business_id: null,
      fileUploadLogos: [],
      isLandlineModalOpen: false,
      isOpenModalDisableLandline: false,
      processingLandline: false,
      errorMessageLandline: false,
      forceButton: false,
      fileWidgetIcon: [],
      isOpenModalExcludeUrls: false,
      newExcludeUrl: {},
      spinner: {
        fileUploadLogos: {
          active: false,
          color: "#ffffff",
        },
        fileWidgetIcon: {
          active: false,
          color: "#ffffff",
        },
      },
      loading: true,
      timezones,
      callingTypes: [
        {
          id: 1,
          description: "Call all at once",
        },
      ],
      notificationTypes: [
        {
          id: 1,
          description: "Phone",
        },
        {
          id: 2,
          description: "SMS",
        },
        {
          id: 3,
          description: "Phone & SMS",
        },
      ],
      defaultNotificationType: {
        id: 1,
        description: "Phone",
      },
      defaultCallingType: {
        id: 1,
        description: "Call all at once",
      },
      form: {},
      formIsDirty: true,
      isCallbackNumberUpdated: false,
      embedCode: null,
      fieldConfig: {
        widgetSetting: {
          headerTitle: {
            label: "Header Title",
            textLimit: 225,
            required: true,
          },
          mobileButtonSmsLabel: {
            label: "Text Label",
            textLimit: 25,
            required: true,
          },
          mobileButtonCallLabel: {
            label: "Call Label",
            textLimit: 25,
            required: true,
          },
          mobileSmsDefaultLabel: {
            label: "SMS Default Text",
            tooltip: "This content is automatically added to the text when the visitor clicks the text button on their mobile device.",
            textLimit: 225,
            required: false,
          },
          departmentText: {
            label: "Department Content Label",
            textLimit: 225,
            required: true,
          },
          gateText: { label: "Gate Text", textLimit: 225, required: true },
          callConnectText: { label: "Call Connect Text", textLimit: 225, required: true },
          smsConnectText: { label: "SMS Connect Text", textLimit: 225, required: true },
          recordConnectText: { label: "VoiceRecord Connect Text", textLimit: 225, required: true },
          introText: {
            label: "Voice Call - During Business Hours",
            textLimit: 225,
            required: true,
          },
          offlineText: {
            label: "Voice Call - Off Business Hours",
            textLimit: 225,
            required: true,
          },
          smsText: {
            label: "SMS - All The Time",
            textLimit: 225,
            required: true,
          },
          voiceRecordText: {
            label: "Voice Record - All The Time",
            textLimit: 225,
            required: true,
          },
          smsResponseTitleText: {
            label: "SMS - Response Title",
            textLimit: 225,
            required: true,
          },
          smsResponseText: {
            label: "SMS - Response Message",
            textLimit: 225,
            required: true,
          },
          voiceResponseTitleText: {
            label: "Voice Call - Response Title",
            textLimit: 225,
            required: true,
          },
          voiceResponseText: {
            label: "Voice Call - Response Message",
            textLimit: 225,
            required: true,
          },
          scheduledVoiceResponseText: {
            label: "Scheduled Call - Response Message",
            textLimit: 225,
            required: true,
          },
          voiceRecordResponseTitleText: {
            label: "Voice Record - Response Title",
            textLimit: 225,
            required: true,
          },
          voiceRecordResponseText: {
            label: "Voice Record - Response Message",
            textLimit: 225,
            required: true,
          },
          tooltipText: { label: "Tooltip Text", textLimit: 50, required: true },
          gdprText: {
            label: "GDPR/Legal Disclaimer",
            textLimit: 225,
            required: false,
          },
          gdprSmsText: {
            label: "GDPR/Legal Disclaimer",
            textLimit: 225,
            required: false,
          },
          promotionText: {
            label: "Promotion Text",
            textLimit: 1000,
            required: false,
          },
          autoResponderText: {
            label: "Auto Responder Text",
            textLimit: 800,
            required: false,
          },
          autoResponderMissedCall: {
            label: "Auto Responder Text",
            textLimit: 800,
            required: false,
          },
          autoResponderScheduledCall: {
            label: "Auto Responder Text",
            textLimit: 800,
            required: false,
          },
          buttonLabel: {
            label: "Call Button Label",
            textLimit: 40,
            required: true,
          },
          scheduleButtonLabel: {
            label: "Scheduled Call Button Label",
            textLimit: 40,
            required: true,
          },
          smsButtonLabel: {
            label: "SMS Button Label",
            textLimit: 40,
            required: true,
          },
          voiceRecordButtonLabel: {
            label: "Voice Record Button Label",
            textLimit: 40,
            required: true,
          },
          backButtonLabel: {
            label: "Back Button Label",
            textLimit: 40,
            required: true,
          },
          mobileNumberLabel: {
            label: "Mobile Number Label",
            textLimit: 40,
            required: true,
          },
          messageLabel: {
            label: "Message Label",
            textLimit: 40,
            required: true,
          },
          nameLabel: {
            label: "Label",
            textLimit: 40,
            required: true,
          },
          emailLabel: {
            label: "Label",
            textLimit: 40,
            required: true,
          },
          callLabel: {
            label: "Call Link Label",
            textLimit: 40,
            required: true,
          },
          callScheduleLabel: {
            label: "Call Schedule Link Label",
            textLimit: 40,
            required: true,
          },
          poweredByLabel: {
            label: "Powered By Label",
            textLimit: 255,
            required: true,
          },
        },
      },
      notificationEmailEnable: null,
      notificationPhoneEnable: null,
      smsNotificationEmailEnable: null,
      smsNotificationPhoneEnable: null,
      numberOfRetries: [
        { key: 0, value: "No" },
        { key: 1, value: "1" },
        { key: 2, value: "2" },
        { key: 3, value: "3" },
      ],
      autoPopupValues: [
        { key: 1, value: "No Delay" },
        { key: 5, value: "5" },
        { key: 10, value: "10" },
        { key: 15, value: "15" },
        { key: 20, value: "20" },
        { key: 25, value: "25" },
        { key: 30, value: "30" },
        { key: 35, value: "35" },
        { key: 40, value: "40" },
        { key: 45, value: "45" },
        { key: 50, value: "50" },
        { key: 55, value: "55" },
        { key: 60, value: "60" },
      ],
      connectionTimeoutValues: [
        "10",
        "20",
        "30",
        "40",
        "50",
        "60",
        "70",
        "80",
        "90",
      ],
      retryDelayValues: ["1", "2", "3", "4", "5"],
      previewMode: "call",
      widgetType: [],
      widgetTypeArr: [
        {
          id: 1,
          label: "Phone Only",
        },
        {
          id: 2,
          label: "Text Only",
        },
        {
          id: 3,
          label: "Phone & Text",
        },
      ],
      widgetLabels: {
        "call-company-number-headline": "Specify the Company Numbers",
        "call-company-number":
          "Calls from the website will be made from these numbers.",
        "agent-headline": "Specify the Company Agents",
        "call-agent":
          "Calls from the website will be made to these agents, each agent is called simultaneously. In order to accept the lead the agent must press 1 on their phone.",
        "call-notification-headline":
          "Specify The Company Notification For Missed & Scheduled Calls",
        "call-notification":
          "Notifications will be sent to this cell phone number and email address.",
        "call-officehour-headline":
          "Specify the office hours of your business",
        "call-officehour":
          "Widget Install Call is not available out of office hrs.",
        "message-company-number-headline": "Specify the Company Numbers",
        "message-company-number":
          "Messages from the website will be sent from these numbers.",
        "agent-headline": "Specify the Company Agents",
        "message-agent":
          "Messages from the website will be sent to these agents.",
        "all-agent": "List of company agents handle call and sms requests.",
        "message-notification-headline":
          "Specify The Company Notification For New Text Messages",
        "message-notification":
          "Notifications will be sent to this cell phone number and email address.",
        "message-officehour-headline":
          "Specify the office hours of your business",
        "message-officehour":
          "Auto Responder is not sent out during off office hrs.",
      },
      wizardSteps: {
        settings: {
          label: "Company Info",
          slot: "settings",
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          onNext: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
            this.saveInstall();
          },
          isValid: async() => {
            if (!this.twilioNumber || this.twilioStatus !== 'valid') {
              Vue.$toast.open({
                message: "Please Assign Custom Phone Number",
                type: "default",
              });
              return false;
            }

            if (
              !/^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
                $("#url").val()
              )
            ) {
              Vue.$toast.open({
                message: "Enter a valid url",
                type: "default",
              });
              return false;
            }

            if (!this.form.callback_phonenumber) {
              Vue.$toast.open({
                message: "Default Business Number is required",
                type: "default",
              });
              return false;
            }

            if (!this.form.landline_number && this.form.landline_enabled) {
              Vue.$toast.open({
                message: "Landline Number is required",
                type: "default",
              });
              return false;
            }
            let valid = await this.$refs.companyInfoForm.validate()
            return valid;
          },
        },
        agents: {
          label: "",
          slot: "agents",
          parent: "settings",
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          onNext: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
            this.saveInstall();
          },
          isValid: () => {
            let isValid = false;
            if (!this.departmentEnabled) {
              let isValid = this.form.voiceAgents.length;

              if (this.businessCallEnabled && !isValid) {
                Vue.$toast.open({
                  message: "At least one voice agenet is required!",
                  type: "error",
                });
                return false;
              }
              return true;
            } else {
              if (!this.form.departments.length) {
                Vue.$toast.open({
                  message: "At least one department is required!",
                  type: "error",
                });
                return false;
              }
              this.form.departments.forEach((item) => {
                if (!item) {
                  Vue.$toast.open({
                    message: "Please select one of departments!",
                    type: "error",
                  });
                  isValid = false;
                  return true;
                } else {
                  isValid = true;
                }
              });
              return isValid;
            }
          },
        },
        notifications: {
          label: "",
          slot: "notifications",
          parent: "settings",
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          onNext: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
            this.saveInstall();
          },
          isValid: () => {
            if (this.notificationEmailEnable && !this.form.notification_email) {
              Vue.$toast.open({
                message:
                  "Please input notification email for Call to move forward!",
                type: "default",
              });
              return false;
            }

            if (this.notificationPhoneEnable && !this.form.notification_phone) {
              Vue.$toast.open({
                message:
                  "Please input notification phone for Call to move forward!",
                type: "default",
              });
              return false;
            }

            if (
              this.smsNotificationEmailEnable &&
              !this.form.sms_notification_email
            ) {
              Vue.$toast.open({
                message:
                  "Please input notification email for SMS to move forward!",
                type: "default",
              });
              return false;
            }

            if (
              this.smsNotificationPhoneEnable &&
              !this.form.sms_notification_phone
            ) {
              Vue.$toast.open({
                message:
                  "Please input notification phone for SMS to move forward!",
                type: "default",
              });
              return false;
            }

            return true;
          },
        },
        officeHours: {
          label: "Office Hours",
          slot: "officeHours",
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          onNext: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
            if (!this.embedCode) {
              this.getEmbedCode();
            }
            this.saveInstall();
          },
          isValid: () => {
            return true;
          },
        },
        apps: {
          label: "Widget Settings",
          slot: "apps",
          isValid: () => {
            let result = true;
            Object.keys(this.fieldConfig.widgetSetting).forEach(
              (key) => {
                const { label, textLimit, required } = this.fieldConfig.widgetSetting[key]
                if (required && !this.form.properties[key]) result = false;
                if (
                  this.form.properties[key] &&
                  this.form.properties[key].length >= textLimit
                ) {
                  this.showToast(
                    `${label} is over limit of ${textLimit} characters`,
                    { icon: "fa-exclamation-triangle" }
                  );
                  result = false;
                }
              }
            );
            return result;
          },
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          onNext: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
            if (!this.embedCode) {
              this.getEmbedCode();
            }
            this.saveInstall();
          },
        },
        code: {
          isValid: () => true,
          onBack: () => {
            window.scrollTo(0, 0);
            window.dispatchEvent(new Event("resize"));
          },
          label: "Widget Code",
          slot: "code",
        },
      },
      installSteps: [],
      departments: [],
      users: [],
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
    };
  },

  created() {
    this.form = cloneDeep(defaultInstallData);
    this.check_if_install_modified = false;

    if (!this.$route.params.id) {
      this.form.properties.type = { ...this.defaultNotificationType };
      this.form.properties.callingType = { ...this.defaultCallingType };

      if (this.localTimeZone) {
        this.form.properties.timezone = {
          value: this.localTimeZone.value,
          description: this.localTimeZone.description,
        };
      }
    }
    this.loading = false;
  },
  mounted() {
    if (this.$route.path.match(/add/)) {
      this.loading = true;
      this.saveInstall()
        .then((success) => {
          this.loading = false;
          if (success) return;
          this.$router.push({ name: "business.installs" });
        })
        .catch(() => {
          this.loading = false;
        });
    }

    if (!!this.$route.params.id) {
      this.getInstall(this.$route.params.id);
      this.getInstallTwilioStatus(this.$route.params.id);
    }

    this.installSteps = [
      this.wizardSteps.settings,
      this.wizardSteps.agents,
      // this.wizardSteps.notifications,
      this.wizardSteps.officeHours,
      this.wizardSteps.apps,
      this.wizardSteps.code,
    ];

    this.loadDepartment();
    this.loadUsers();
  },
  computed: {
    vcardLogo() {
      return this.business.properties && this.business.properties.images && this.business.properties.images['logo'] && this.business.properties.images['logo'].url
    },
    vcardLogoStyle() {
      if (this.vcardLogo)
        return {
          "background-image": `url("${this.vcardLogo}")`,
        };
      return {}
    },
    vcardInfo() {
      return {
        'logo': this.vcardLogo,
        'name': this.form.properties.vcard_name ? this.form.properties.vcard_name: this.businessName,
        'phone': this.form.properties.vcard_phone ? this.form.properties.vcard_phone: this.twilioNumber,
        'email': this.form.properties.vcard_email,
        'website': this.form.properties.vcard_website ? this.form.properties.vcard_website: this.form.properties.website,
      }
    },

    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },

    listCountries() {
      let listCountries = getCodeList()
      let result = []

      for (let code of Object.keys(listCountries)) {
        result.push({ value: code, name: listCountries[code] })
      }

      return result
    },

    optText() {
      return this.$store.getters["auth/optText"];
    },

    showBackButton() {
      return this.widgetDepartmentShow || this.widgetGateShow
    },
    showBrand() {
      return !this.form.properties.removeBranding && this.poweredBy && this.poweredBy.enabled
    },
    poweredBy() {
      return this.$store.state.auth.user.agency.poweredBy;
    },
    previewWrapperStyle() {
      const primaryRgbColor = utils.hex2rgb(
        this.form.properties.widgetPrimaryColor,
        1
      );
      const secondaryRgbColor = utils.hex2rgb(
        this.form.properties.widgetSecondaryColor,
        1
      );
      const lightBgColor = utils.hex2rgb(
        this.form.properties.widgetPrimaryColor,
        0.2
      );
      const dimRgbColor = utils.hex2rgb(
        this.form.properties.widgetDimOverlayColor,
        0.7
      );
      return {
        "--primaryColor": primaryRgbColor.css,
        "--secondaryColor": secondaryRgbColor.css,
        "--lightBgColor": lightBgColor.css,
        "--dimColor": dimRgbColor.css,
      };
    },

    widgetButtonWrapper() {
      const res = {
        "bottom": this.form.properties.widgetMarginY + 'px'
      };
      if (this.form.properties.widgetPosition == 'left') {
        res['left'] = this.form.properties.widgetMarginX + 'px'
      } else if (this.form.properties.widgetPosition == 'right') {
        res['right'] = this.form.properties.widgetMarginX + 'px'
      }
      return res
    },

    pWidget() {
      const res = {
        "bottom": (parseInt(this.form.properties.widgetMarginY) + 90) + 'px'
      };
      if (this.form.properties.widgetPosition == 'left') {
        res['left'] = this.form.properties.widgetMarginX + 'px'
      } else if (this.form.properties.widgetPosition == 'right') {
        res['right'] = this.form.properties.widgetMarginX + 'px'
      }
      return res
    },

    countries() {
      return this.$store.getters["countryWithCode"];
    },
    showSmsRestriction() {
      return (
        this.smsEnabled && !["US", "CA"].includes(this.twilioNumberCountry)
      );
    },
    widgetDepartmentActive() {
      return this.departmentEnabled && this.form.departments.length > 1;
    },

    businessOwner() {
      return this.$store.getters["auth/businessOwner"];
    },

    widgetTypePhone() {
      return this.$store.getters["auth/widgetTypePhone"];
    },

    widgetTypeText() {
      return this.$store.getters["auth/widgetTypeText"];
    },

    business() {
      return this.$store.state.auth.user.business;
    },

    businessName() {
      return this.business && this.business.name
    },

    businessEmail() {
      return this.user && this.user.email
    },

    departmentList() {
      return this.departments
        .filter((item) => {
          return !(
            this.form &&
            this.form.departments.find((item1) => item1.id === item.id)
          );
        })
        .map((item) => {
          return {
            ...item,
            label: item.name,
            show: false,
          };
        });
    },

    userList() {
      return this.users.map((item) => {
        return {
          ...item,
          label: item.full_name + " ( " + item.phone + " ) ",
        };
      });
    },

    departmentEnabled() {
      return this.businessDepartmentEnabled && this.form.department_enabled;
    },

    showDefaultBusinessNumber() {
      return (
        this.businessWidgetType != 2 ||
        (this.businessWidgetType == 2 && !this.form.landline_enabled)
      );
    },
    showLandlineSection() {
      return (
        this.businessLandlineEnabled &&
        this.businessSmsEnabled &&
        this.twilioNumber
      );
    },
    callMessageText() {
      return this.callEnabled ? "call" : "message";
    },
    businessCallMessageText() {
      if (this.businessSmsEnabled && this.businessCallEnabled) return "all";
      if (this.businessSmsEnabled) return "call";
      if (this.businessCallEnabled) return "message";
    },
    businessCallerIdEnabled() {
      return (
        [1, 3].includes(this.businessWidgetType) &&
        this.$store.state.auth.user &&
        this.$store.state.auth.user.business &&
        this.$store.state.auth.user.business.caller_id_enabled
      );
    },
    businessLandlineEnabled() {
      return (
        [2, 3].includes(this.businessWidgetType) &&
        this.$store.state.auth.user &&
        this.$store.state.auth.user.business &&
        this.$store.state.auth.user.business.landline_enabled
      );
    },
    businessDepartmentEnabled() {
      return this.$store.getters["auth/departmentEnabled"];
    },
    callerIdEnabled() {
      return this.callEnabled && this.businessCallerIdEnabled;
    },
    formEnabled() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.business &&
        this.$store.state.auth.user.business.form_enabled
      );
    },
    agencyRemoveBrandingEnabled() {
      return this.$store.getters['auth/agencyRemoveBrandingEnabled']
    },
    brandRemoveEnabled() {
      return this.agencyRemoveBrandingEnabled && this.business && this.business.remove_branding
    },
    businessWidgetType() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.business)
        return this.$store.state.auth.user.business.widget_type;
      return 1;
    },
    businessSmsEnabled() {
      return [2, 3].includes(this.businessWidgetType);
    },
    businessCallEnabled() {
      return [1, 3].includes(this.businessWidgetType);
    },
    smsEnabled() {
      return (
        [2, 3].includes(this.businessWidgetType) &&
        [2, 3].includes(this.form.widget_type)
      );
    },
    callEnabled() {
      return (
        [1, 3].includes(this.businessWidgetType) &&
        [1, 3].includes(this.form.widget_type)
      );
    },

    voiceRecordEnabled() {
      return this.form && this.form.properties && this.form.properties.voiceRecordingEnabled
    },

    gateEnabled() {
      return this.smsEnabled && this.callEnabled || this.voiceRecordEnabled
    },

    twilioNumberCountry() {
      let country = "US";
      let number = this.form.number;
      if (
        this.businessLandlineEnabled &&
        this.businessWidgetType == 2 &&
        this.form.landline_enabled &&
        this.form.landline_number
      ) {
        number = this.form.landline_number;
      }

      if (number) {
        const phoneNumber = parsePhoneNumber(number);
        country = phoneNumber.country;
      }
      return country;
    },

    twilioNumber: {
      get() {
        let number = this.form.number;
        if (
          this.businessLandlineEnabled &&
          this.businessWidgetType == 2 &&
          this.form.landline_enabled &&
          this.form.landline_number
        ) {
          number = this.form.landline_number;
        }

        if (number) {
          const phoneNumber = parsePhoneNumber(number);
          number = phoneNumber.formatInternational();
        }

        return number;
      },
      set(v) {
        return v;
      },
    },
    widgetIconImg() {
      return this.form.properties.widgetIcon.path
    },
    widgetProfileImg() {
      const url =
        this.form.properties &&
          this.form.properties.logo &&
          this.form.properties.logo.path
          ? this.form.properties.logo.path
          : require("../../assets/images/widget/support.webp");
      return {
        "background-image": `url("${url}")`,
      };
    },
    widgetTooltipText() {
      let label = "";
      if (this.previewMode == "sms") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.tooltipSmsText;
      } else {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.tooltipText;
      }
      return label;
    },
    widgetButtonLabel() {
      let label = "";
      if (
        this.previewMode == "schedule" &&
        this.form.properties.callScheduling
      ) {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.scheduleButtonLabel
      } else if (this.previewMode == "sms") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.smsButtonLabel;
      } else if (this.previewMode == "voice") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.voiceRecordButtonLabel;
      } else {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.buttonLabel;
      }
      return label;
    },

    widgetResponseText() {
      let label = "";
      if (this.previewMode == "sms") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.smsResponseText;
      } else if (this.previewMode == "voice") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.voiceRecordResponseText;
      } else if (this.previewMode == "scheduledCall") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.scheduledVoiceResponseText;
      } else {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.voiceResponseText;
      }
      return label;
    },

    widgetResponseMessage() {
      let label = 'Test Message';
      if (this.previewMode == "voice") {
        label = 'VoiceRecording is submitted'
      } else if (this.previewMode == "call") {
        label = 'This is a Call Request'
      } else if (this.previewMode == "scheduledCall") {
        label = 'This is a Scheduled Call Request'
      }
      return label
    },

    widgetResponseTitle() {
      let label = "";
      if (this.previewMode == "sms") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.smsResponseTitleText
      } else if (this.previewMode == "voice") {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.voiceRecordResponseTitleText
      } else {
        label = this.form.properties.languageList[this.mainLanguageIndex].value.voiceResponseTitleText
      }
      return label;
    },

    previewHeadText() {
      let text = "";
      if (
        this.previewMode == "schedule" &&
        this.form.properties.callScheduling
      ) {
        text = this.form.properties.languageList[this.mainLanguageIndex].value.offlineText;
      } else if (this.previewMode == "sms") {
        text = this.form.properties.languageList[this.mainLanguageIndex].value.smsText;
      } else if (this.previewMode == "voice") {
        text = this.form.properties.languageList[this.mainLanguageIndex].value.voiceRecordText;
      } else {
        text = this.form.properties.languageList[this.mainLanguageIndex].value.introText;
      }
      return text;
    },

    user() {
      return this.$store.state.auth.user;
    },

    getTimezoneDesc() {
      let result = "";
      this.timezones.forEach((record) => {
        record.utc.forEach((timezoneName) => {
          if (timezoneName == this.form.properties.timezone.value) {
            const timezone = moment().tz(timezoneName);
            result = `(UTC${timezone.format("Z")}) ${timezoneName}`;
          }
        });
      });
      return result;
    },

    addOrEdit() {
      if (this.$route.path.match(/add/)) {
        return "Add";
      } else {
        return "Edit";
      }
    },

    timeZonesForSelect() {
      const result = [];
      this.timezones.forEach((record) => {
        record.utc.forEach((timezoneName) => {
          const timezone = moment().tz(timezoneName);
          result.push({
            value: timezoneName,
            description: `(UTC${timezone.format("Z")}) ${timezoneName}`,
          });
        });
      });

      return result;
    },
    localTimeZone() {
      const momentTimeZone = moment.tz.guess();
      return this.timeZonesForSelect.find((t) => {
        return t.value === momentTimeZone;
      });
    },
    localTime() {
      return moment().format("MMM D, YYYY hh:mm A");
    },
    utcTime() {
      return moment().utc().format("MMM D, YYYY hh:mm A");
    },
    ...mapState("install", ["processing"]),
  },

  watch: {
    widgetDepartmentActive: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue) {
            this.widgetDepartmentShow = true;
            this.widgetCurrentScreen = "department";
          } else {
            this.widgetDepartmentShow = false;
            this.widgetCurrentScreen = "gate";
          }
        }
      },
    },
    widgetType: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.resetWidget();
        }
      },
    },

    voiceRecordEnabled: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.resetWidget();
        }
      },
    },

    fileUploadLogos: function () {
      this.uploadLogo();
    },

    fileWidgetIcon: function () {
      this.uploadWidgetIcon();
    },

    form: {
      deep: true,
      sync: true,
      handler: function () {
        this.formIsDirty = true;
      },
    },

    "form.callback_phonenumber": {
      handler: function (newValue, oldValue) {
        if (this.isFetched && newValue && oldValue && newValue != oldValue) {
          this.form.caller_id = false;
        }
      },
    },

    notificationEmailEnable: function () {
      this.formIsDirty = true;
    },
    notificationPhoneEnable: function () {
      this.formIsDirty = true;
    },
    smsNotificationEmailEnable: function () {
      this.formIsDirty = true;
    },
    smsNotificationPhoneEnable: function () {
      this.formIsDirty = true;
    },
  },
  methods: {
    removeLanguage () {
      let selectedKey = this.selectedMainLanguage.id;
      this.selectedMainLanguage = {"id": "en", "label": "English"};
      this.mainLanguageIndex = this.form.properties.languageList.findIndex(obj => obj.language == this.selectedMainLanguage.id)
      let removeIndexMainLanguage = this.mainLanguageList.findIndex(obj => obj.id == selectedKey)
      this.mainLanguageList.splice(removeIndexMainLanguage, 1);
      let removeIndexInstallProperty = this.form.properties.languageList.findIndex(obj => obj.language == selectedKey)
      this.form.properties.languageList.splice(removeIndexInstallProperty, 1);
      this.showToast("Selected language is removed form the list");
    },
    onSelectLanguage (value) {
      this.mainLanguageIndex = this.form.properties.languageList.findIndex(obj => obj.language == value.id)
    },
    addInstallLanguage (){
      let findIndex = this.form.properties.languageList.findIndex(obj => obj.language == this.selectedLanguage.id)
      if(findIndex == -1) {
        let newItem = {'language' : this.selectedLanguage.id, 'value' : {
        headerTitle: 'Welcome, contact us below',
        departmentText: 'Got a question? Select one of the departments below to ask questions.',
        gateText: 'Got a question? Request an instant call back or text here. We offer 2 quick ways to connect with us.',
        callConnectText: 'Connect Via Phone',
        smsConnectText: 'Connect Via Text',
        recordConnectText: 'Send VoiceRecord',
        introText: 'To get connected with us immediately, enter your number below and we will call or text you in 14 seconds.',
        offlineText: 'Unfortunately, we are out of the office. Schedule a call by entering your information below.',
        smsText: 'Enter your mobile number below and our team will text you shortly.',
        voiceRecordText: 'Enter your mobile number below and our team will text you shortly.',
        smsResponseTitleText: 'We received your message.',
        smsResponseText: 'One of our team representatives will be texting you back from the number below.',
        voiceResponseTitleText: 'We received your call request.',
        voiceResponseText: 'One of our team representatives will call you from the number below.',
        scheduledVoiceResponseText: 'One of our team representatives will call you at scheduled time from the number below.',
        voiceRecordResponseTitleText: 'We received your voice record.',
        voiceRecordResponseText: 'One of our team representatives will be texting you back from the number below.',
        gdprText: 'This call will be recorded for quality and training purposes.',
        gdprSmsText: 'By submitting you agree to receive text messages at the number provided. Message/data rates apply.',
        promotionText: 'By submitting you agree to receive text messages at the number provided. Message/data rates apply.',
        autoResponderText: 'Thanks for your contacting us, We will text you soon.',
        autoResponderMissedCallText: 'Hi there, sorry we weren\'t able to take your phone call in time. How can we help you?',
        autoResponderScheduledCallText: 'You scheduled a call on [time] from website [site], we will be contacting you shortly.',
        tooltipText: 'Click here for an instant callback in 14 seconds.',
        tooltipSmsText: 'Hi there, do you have a question? Text us here...',
        buttonLabel: 'Call Me Now',
        scheduleButtonLabel: 'Schedule a Call',
        smsButtonLabel: 'Send Us A Message',
        voiceRecordButtonLabel: 'Send Us A Record',
        backButtonLabel: 'Back',
        mobileNumberLabel: 'Mobile Number',
        messageLabel: 'Message',
        nameLabel: 'Name',
        emailLabel: 'Email',
        callLabel: 'Call us now',
        callScheduleLabel: 'Choose a time for us to call you',
        poweredByLabel: 'Powered By',
        callWhisperText: 'Your call may be monitored or recorded for quality and training purposes.'
        }};
        this.form.properties.languageList.push(newItem);
        this.mainLanguageList.push(this.selectedLanguage) 
      }
      
      this.isOpenModalLanguage = false
    },
    openModalLanguage () {
      this.isOpenModalLanguage = true
    },
    closeModalLanguage () {
      this.isOpenModalLanguage = false
    },
    resetWidget() {
      this.form.widget_type = this.widgetType['id'];
      switch (this.form.widget_type) {
        case 1:
          this.widgetCurrentScreen = this.widgetDepartmentActive ? "department" : "main";
          if (this.voiceRecordEnabled)
            this.widgetGateShow = true;
          else
            this.widgetGateShow = false;
          this.previewMode = "call";
          break;
        case 2:
          this.widgetCurrentScreen = this.widgetDepartmentActive ? "department" : "main";
          if (this.voiceRecordEnabled)
            this.widgetGateShow = true;
          else
            this.widgetGateShow = false;
          this.previewMode = "sms";
          break;
        case 3:
        default:
          this.widgetCurrentScreen = this.widgetDepartmentActive ? "department" : "gate";
          this.widgetGateShow = true;
          this.previewMode = "call";
          break;
      }
    },

    handleResponderTextUploadedImage(images) {
      this.form.properties.autoResponderTextImgs = images
    },

    handleResponderCallUploadedImage(images) {
      this.form.properties.autoResponderMissedCallImgs = images
    },

    onEnableMobileButton(val) {
      this.showMobileScreen(val)
    },
    showMobileScreen(val) {
      if (val)
        this.previewMainViewType = 'mobile-button'
      else
        this.previewMainViewType = 'main'
    },
    showDepartment(index) {
      this.form.departments[index].show = this.form.departments[index].show
        ? false
        : true;
    },

    closeDepartmentModal() {
      this.isOpenModalDepartment = false;
      this.newDepartment = undefined;
    },

    handleAddDepartment() {
      if (
        this.form.departments.find((item) => item.id === this.newDepartment.id)
      ) {
        Vue.$toast.open({
          message:
            "This department already exists in list, Please select another agent.",
          type: "error",
        });
        return false;
      }
      this.form.departments.push(this.newDepartment);
      this.closeDepartmentModal();
    },

    loadUsers() {
      this.$store
        .dispatch("businessUser/agents")
        .then((data) => {
          this.users = data;
        })
        .catch((err) => { });
    },

    addAgent(type = "voice") {
      this.newAgent = undefined;
      this.addType = type;
      this.isOpenModalAgent = true;
    },

    closeAgentModal() {
      this.isOpenModalAgent = false;
      this.newAgent = undefined;
    },

    handleAddAgent() {
      let list =
        this.addType === "voice" ? this.form.voiceAgents : this.form.smsAgents;
      if (list.find((item) => item.id === this.newAgent.id)) {
        Vue.$toast.open({
          message:
            "This Agent already exists in list, Please select another agent.",
          type: "error",
        });
        return false;
      }
      list.push(this.newAgent);
      this.closeAgentModal();
    },

    loadDepartment() {
      this.$store
        .dispatch("department/all")
        .then((data) => {
          this.departments = data;
        })
        .catch((err) => { });
    },

    addDepartment() {
      this.isOpenModalDepartment = true;
    },

    deleteDepartmentItem(index) {
      this.form.departments.splice(index, 1);
    },

    onClickLandline() {
      this.isLandlineModalOpen = true;
    },

    onDisableLandline() {
      this.isOpenModalDisableLandline = true;
    },

    closeLandlineModal() {
      this.isLandlineModalOpen = false;
    },

    handleAssignLandline(isForce = false) {
      this.errorMessageLandline = null;
      this.forceButton = false;
      const payload = {
        installId: this.form.id,
        number: this.form.landline_number,
        isForce: isForce,
      };
      this.processingLandline = true;
      this.$store
        .dispatch("install/assignLandlineNumber", payload)
        .then((res) => {
          this.processingLandline = false;
          if (res.success) {
            this.onLandlineNumberAssigned(res.data);
            this.closeLandlineModal();
          } else {
            this.showToast("Something went wront, Try again later.");
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            const { type, status, message } = err.response.data;
            if (type === 1) {
              this.errorMessageLandline = message;
              if (status === "used") {
                this.forceButton = true;
              }
            }
          }
          this.processingLandline = false;
        });
    },

    onLandlineNumberAssigned(data) {
      const { capabilities, number } = data;
      this.form.landline_number = number;
      this.form.landline_enabled = true;
      this.form.twilio_sms = capabilities.sms;
      this.$store.dispatch("install/fetchAllInstalls");
    },

    handleDisableLandline() {
      const payload = {
        installId: this.form.id,
      };
      this.processingLandline = true;
      this.$store
        .dispatch("install/disableLandlineNumber", payload)
        .then((res) => {
          this.processingLandline = false;
          if (res.success) {
            this.onLandlineNumberDisabled();
            this.isOpenModalDisableLandline = false;
          } else {
            this.showToast("Something went wront, Try again later.");
          }
        })
        .catch((err) => {
          this.processingLandline = false;
        });
    },

    onLandlineNumberDisabled() {
      this.form.landline_number = null;
      this.form.landline_enabled = false;
      this.$store.dispatch("install/fetchAllInstalls");
    },

    onChangeTwilioNumber(data) {
      this.$store.dispatch("auth/getProfile");

      const { capabilities, number, type } = data;
      this.form.twilio_sms = capabilities["sms"];
      this.form.twilio_voice = capabilities["voice"];
      if (type === "hosted") {
        this.form.landline_number = number;
        this.form.landline_enabled = true;
      } else {
        this.form.number = number;
      }
      this.twilioStatus = 'valid'
      this.getInstallTwilioStatus(this.$route.params.id);
      this.$store.dispatch("install/fetchAllInstalls");
    },

    handleFocus(type) {
      if (type == 'mobile-button') {
        this.previewMainViewType = 'mobile-button'
      } else {
        if (type == "tooltip") {
          this.showTooltip = true;
          this.previewMode = this.form.widget_type === 2 ? 'sms' : 'call'
        } else {
          this.showTooltip = false;
        }
        if (type == "department") {
          this.backToMainScreen();
        } else if (type == "gate") {
          this.showGateScreen();
        } else if (type == "call" || type == "sms" || type == "voice") {
          this.showMainScreen(type);
        } else if (type == "smsResponse") {
          this.showResponseScreen('sms');
        } else if (type == "voiceResponse") {
          this.showResponseScreen('call');
        } else if (type == "scheduledVoiceResponse") {
          this.showResponseScreen('scheduledCall');
        } else if (type == "voiceRecordResponse") {
          this.showResponseScreen('voice');
        } else if (type !== "tooltip") {
          this.showMainScreen("call");
          this.toggleWidgetView("schedule");
        }
        this.previewMainViewType = 'main'
      }
    },

    handleBlur() {
      // this.form.widget_type == 3 && this.backToMainScreen();
    },

    backToMainScreen() {
      if (this.widgetDepartmentShow) this.widgetCurrentScreen = "department";
      else this.widgetCurrentScreen = "gate";
    },

    showGateScreen() {
      if (this.widgetGateShow) this.widgetCurrentScreen = "gate";
      else this.widgetCurrentScreen = "main";
    },

    showMainScreen(type) {
      this.widgetCurrentScreen = "main";
      this.toggleWidgetView(type);
    },

    showResponseScreen(type) {
      this.widgetCurrentScreen = "response";
      if (type == 'sms') {
        this.previewMode = 'sms'
      } else if (type == 'voice') {
        this.previewMode = 'voice'
      } else if (type == 'scheduledCall') {
        this.previewMode = 'scheduledCall'
      } else {
        this.previewMode = 'call'
      }
    },

    openVerifyModal() {
      this.phoneVerifyCode = "";
      this.isVerifyModalOpen = true;
    },

    closeVerifyModal() {
      this.isVerifyModalOpen = false;
    },

    onVerifyOk() {
      this.isVerifyLoading = true;
      const requestParam = {
        id: this.$route.params.id,
        number: this.form.callback_phonenumber,
      };
      this.$store
        .dispatch("install/checkCallerId", requestParam)
        .then((res) => {
          this.isVerifyLoading = false;
          if (res.success) {
            this.form.caller_id = true;
            this.isVerifyModalOpen = false;
          } else {
            this.showToast("Something went wront, Try again later.");
          }
        })
        .catch((err) => {
          this.isVerifyLoading = false;
          if (err.response && err.response.data && err.response.data.message) {
            this.showToast(err.response.data.message);
          }
        });
    },

    checkCallerID() {
      this.isVerifyLoading = true;

      const requestParam = {
        id: this.$route.params.id,
        number: this.form.callback_phonenumber,
      };

      this.$store
        .dispatch("install/checkCallerId", requestParam)
        .then((res) => {
          this.isVerifyLoading = false;
          if (res.success) {
            this.form.caller_id = true;
          } else {
            this.openVerifyModal();
          }
        })
        .catch((err) => {
          this.isVerifyLoading = false;
          this.openVerifyModal();
        });
    },

    verifyPhonNumber() {
      this.isVerifyLoading = true;
      this.phoneVerifyCode = "";
      const requestParam = {
        id: this.$route.params.id,
        number: this.form.callback_phonenumber,
      };

      this.$store
        .dispatch("install/verifyPhoneNumber", requestParam)
        .then((res) => {
          this.isVerifyLoading = false;
          if (res.success) {
            this.phoneVerifyCode = res.data.code;
            this.isVerifyModalOpen = true;
          } else {
            this.showToast("Something went wront, Try again later.");
          }
        })
        .catch((err) => {
          this.isVerifyLoading = false;
          if (err.response && err.response.data && err.response.data.message) {
            this.showToast(err.response.data.message);
          }
        });
    },

    disableCallerID() {
      this.form.caller_id = false;
    },

    toggleWidgetView(type) {
      if (type == "call-schedule") {
        if (!["call", "schedule"].includes(this.previewMode)) {
          this.previewMode = "call";
        }
      } else {
        if (!this.callEnabled && type === 'call') {
          this.previewMode = 'sms';
        } else if (!this.smsEnabled && type === 'sms') {
          this.previewMode = 'call';
        } else {
          this.previewMode = type;
        }
      }
    },

    getRGBColor(hexColor) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
      if (!result) return false;
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
    },

    getEmbedCode() {
      if (this.twilioNumber) {
        this.$store
          .dispatch("install/getEmbedCode", this.$route.params.id)
          .then((res) => (this.embedCode = res.data.code));
      }
    },

    getInstallTwilioStatus(id) {
      this.$store
        .dispatch("install/fetchInstallTwilioStatus", id)
        .then((data) => {
          this.twilioStatus = data.data
        })
        .catch((e) => {
        });
    },

    getInstall(id) {
      this.loading = true;

      this.$store
        .dispatch("install/fetchInstall", id)
        .then((data) => {
          this.isFetched = true;

          let tpl = cloneDeep(defaultInstallData);
          this.form = merge(tpl, data.data);
          if (this.businessWidgetType != 3) {
            this.form.widget_type = this.businessWidgetType;
          }
          this.widgetType = this.widgetTypeArr.find(
            (item) => item.id == this.form.widget_type
          );
          this.form.properties.type = { ...this.defaultNotificationType };
          this.form.properties.callingType = { ...this.defaultCallingType };
          this.form.properties.voiceRecordingLimit = this.form.properties.voiceRecordingLimit || 1
          if (Array.isArray(this.form.properties.customFields)) {
            this.form.properties.customFields = {}
          }

          this.$route.meta.displayName = `Edit ${this.form.properties.website}`;

          this.notificationEmailEnable = ["all", "Email"].includes(
            this.form.notification_type
          );
          this.notificationPhoneEnable = ["all", "SMS"].includes(
            this.form.notification_type
          );
          this.smsNotificationEmailEnable = ["all", "Email"].includes(
            this.form.sms_notification_type
          );
          this.smsNotificationPhoneEnable = ["all", "SMS"].includes(
            this.form.sms_notification_type
          );
          this.form.departments = this.form.departments.map((item) => {
            return {
              ...item,
              label: item.name,
              show: false,
            };
          });
        
          this.selectedMainLanguage = languageList.find(language => language.id == 'en');
          this.mainLanguageList = this.form.properties.languageList.map(row => languageList.find(language => language.id === row.language));
          this.mainLanguageIndex = this.form.properties.languageList.findIndex(obj => obj.language === this.selectedMainLanguage.id)
          this.loading = false;
          this.formIsDirty = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$router.push({ name: "business.installs.active" });
        });
    },

    isUrlValid(url) {
      return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
        url
      );
    },
    uploadWidgetIcon() {
      const icon = this.fileWidgetIcon.length
        ? this.fileWidgetIcon[this.fileWidgetIcon.length - 1]
        : null;
      if (!(icon instanceof File)) return;

      this.spinner.fileWidgetIcon.active = true;
      let postData = new FormData();
      postData.append("file", icon);
      const param = {
        id: this.form.id,
        data: postData
      }
      this.$store
        .dispatch("install/uploadWidgetIcon", param)
        .then((data) => {
          if (data.success) {
            this.form.properties.widgetIcon = data.file;
          }
          this.fileWidgetIcon = [];
          this.spinner.fileWidgetIcon.active = false;
        })
        .catch((err) => {
          this.fileWidgetIcon = [];
          this.spinner.fileWidgetIcon.active = false;
        });
    },

    deleteWidgetIcon() {
      if (!this.form.properties.widgetIcon) return;

      this.spinner.fileWidgetIcon.active = true;
      this.$store
        .dispatch("install/deleteWidgetIcon", this.form.id)
        .then((data) => {
          if (data.success) {
            this.form.properties.widgetIcon = null;
          }
          this.spinner.fileWidgetIcon.active = false;
        })
        .catch((err) => {
          this.spinner.fileWidgetIcon.active = false;
        });
    },

    uploadLogo() {
      const logo = this.fileUploadLogos.length
        ? this.fileUploadLogos[this.fileUploadLogos.length - 1]
        : null;
      if (!(logo instanceof File)) return;

      this.spinner.fileUploadLogos.active = true;
      let postData = new FormData();
      postData.append("logo", logo);
      const param = {
        id: this.form.id,
        data: postData
      }
      this.$store
        .dispatch("install/uploadLogo", param)
        .then((data) => {
          if (data.success) {
            this.form.properties.logo = data.logo;
          }
          this.fileUploadLogos = [];
          this.spinner.fileUploadLogos.active = false;
        })
        .catch((err) => {
          this.fileUploadLogos = [];
          this.spinner.fileUploadLogos.active = false;
        });
    },

    deleteLogo() {
      if (!this.form.properties.logo) return;

      this.spinner.fileUploadLogos.active = true;
      this.$store
        .dispatch("install/deleteLogo", this.form.id)
        .then((data) => {
          if (data.success) {
            this.form.properties.logo = null;
          }
          this.spinner.fileUploadLogos.active = false;
        })
        .catch((err) => {
          this.spinner.fileUploadLogos.active = false;
        });
    },

    async saveInstall() {
      if (!this.formIsDirty) {
        return;
      }

      const form = cloneDeep(this.form);
      form.email = this.user.email;

      switch (true) {
        case this.notificationEmailEnable && this.notificationPhoneEnable:
          form.notification_type = "all";
          break;
        case this.notificationEmailEnable:
          form.notification_type = "Email";
          break;
        case this.notificationPhoneEnable:
          form.notification_type = "SMS";
          break;
        default:
          form.notification_type = "none";
          break;
      }

      switch (true) {
        case this.smsNotificationEmailEnable && this.smsNotificationPhoneEnable:
          form.sms_notification_type = "all";
          break;
        case this.smsNotificationEmailEnable:
          form.sms_notification_type = "Email";
          break;
        case this.smsNotificationPhoneEnable:
          form.sms_notification_type = "SMS";
          break;
        default:
          form.sms_notification_type = "none";
          break;
      }

      return new Promise(async (resolve, reject) => {
        const param = {
          id: form.id,
          data: form,
        };
        this.$store
          .dispatch("install/updateInstall", param)
          .then((data) => {
            if (data.success) {
              form.euid = data.data.euid;
              this.formIsDirty = false;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => String(start + idx));
    },
    onCopy: function (e) {
      Vue.$toast.open({
        message: "Script has been copied to clipboard",
        type: "success",
      });
    },
    onError: function (e) {
      this.showToast("Failed to copy code to your clipboard", {
        icon: "fa-exclamation-triangle",
      });
    },

    populateTimes(val) {
      for (var k in this.form.properties.hours) {
        this.form.properties.hours[k] = JSON.parse(JSON.stringify(val)); //again really hate this but if we don't we get attached watchers..
      }
    },
    isFormFieldValid(field) {
      let isValid = false;
      if (this.formFields[field]) {
        isValid =
          this.formFields[field].validated && this.formFields[field].valid;
      }
      return isValid;
    },
    validateFormField(fieldName) {
      this.$validator.validate(fieldName, this[fieldName]);
    },
    wizardCompleted() {
      setTimeout(() => {
        this.$router.push({ name: "business.installs" });
      }, 500);
    },

    deleteAgent(type = "voice", index = 0) {
      if (type === "voice") {
        this.form.voiceAgents.splice(index, 1);
      } else {
        this.form.smsAgents.splice(index, 1);
      }
    },

    onAddExcludeUrls() {
      this.isOpenModalExcludeUrls = true
      this.newExcludeUrl = {
        type: 'Contain'
      }
    },

    onCloseModalExcludeUrls() {
      this.isOpenModalExcludeUrls = false
    },

    handleAddExcludeUrls() {
      this.isOpenModalExcludeUrls = false
      this.form.properties.excludeUrls = this.form.properties.excludeUrls || []
      this.form.properties.excludeUrls.push(this.newExcludeUrl)
    },

    onDeleteExcludeUrl(index) {
      this.form.properties.excludeUrls.splice(index, 1)
    },
  },
};
</script>

<style scoped lang="scss">
.table-agents {
  background-color: #f7f9ff;
}

.btn-phone-verify {
  border-radius: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(29, 113, 233, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(29, 113, 233, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(29, 113, 233, 0);
  }
}

.tooltip-icon {
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
  margin-top: calc(4px - 8px);
}

.form-wizard-tab-content {
  .form-group textarea {
    padding: 10px;
  }
}

.setting-position-img-wrapper {

  .setting-position-switch,
  .setting-mobile-button {
    padding-bottom: 20px;

    .vuestic-switch {
      max-width: 200px;
    }
  }

  .widget-margin-wrapper {
    display: flex;
    align-items: flex-start;

    .widget-margin-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: 180px;
    }
  }

  @media (max-width: 1378px) {
    flex-direction: column;

    .setting-position-wrapper {
      min-width: 300px;
      margin: 0;
      margin-bottom: 20px;
    }

    .setting-img-wrapper {
      width: 100%;
    }
  }
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-title {
  color: #34495e;
}

.setting-block {
  display: flex;
  justify-content: space-between;

  .left-wrapper {
    flex: auto;
    padding-right: 40px;
  }

  .right-wrapper {
    flex: 0 0 415px;
    align-items: stretch;

    @media (max-width: 1196px) {
      flex: 0 0 400px;

      .pwidget {
        max-width: 300px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .left-wrapper {
      width: 100%;
      padding-right: 0px;
    }

    .right-wrapper {
      width: 100%;
    }
  }
}

.selec-div {
  .form-group {
    margin: 0;
    margin-top: 14px;
  }
}

select.form-control {
  width: 100% !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  border-radius: 4px !important;
  padding: 0 10px;
  height: 35px !important;
  background-image: none !important;
}

select.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-input-icon {
  top: 10px !important;
  right: 10px !important;
  z-index: 3 !important;
}

.vuestic-switch-container {
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-gap: 15px;
  align-content: center;

  .vuestic-switch {
    margin: 1rem 0;
    max-height: 38px;
  }

  @media (max-width: 480px) {
    display: block;
  }
}

.ext-btn {
  display: block;
  float: left;
  margin-top: 20px;
}

.widget-color-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .vuestic-color-square {
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }

  .color-text {
    display: block;
    margin-left: 20px;
  }

  .form-group {
    margin-bottom: 0px !important;
  }

  .widget-button-preview {
    margin-left: 50px;

    .widget-button {
      padding: 12px;
      width: 30px;
      height: 30px;
      margin-top: -12px;
      background-color: var(--primaryColor);
    }
  }

  @media (max-width: 1270px) {
    flex-direction: column;

    .widget-button-preview {
      margin: 30px auto;
    }
  }
}

div.widget-button-custom {
  margin-top: -12px;
  cursor: pointer;

  &:hover,
  &.active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.65) !important;
  }
}

button.widget-button {
  border-radius: 50%;
  border: none;
  padding: 18px;
  width: 38px;
  height: 38px;
  line-height: 30px;
  outline: none;
  color: #ffffff;
  opacity: 0.9;
  box-sizing: content-box;
  background-color: var(--primaryColor);
  box-shadow: 0 0 0 0 rgba(29, 113, 233, 1);

  // transform: scale(1);
  // animation: pulse 3s infinite;
  // animation-delay: 2s;
  img {
    height: 100%;
  }

  &:hover,
  &.active {
    opacity: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.65) !important;
  }
}

.lelabel,
.control-label {
  z-index: 0;
}

.preview-wrapper {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 750px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu,
    "Helvetica Neue", Helvetica, sans-serif;
  background-color: #fff;

  .preview-wrapper-dim {
    background-color: var(--dimColor);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .preview-mobile-button-view {
    position: relative;
    top: 700px;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile-button-item {
      flex: 1;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 20px;
      padding: 10px;
      box-sizing: border-box;

      &.call-btn {
        background-color: var(--primaryColor);
      }

      &.sms-btn {
        background-color: var(--secondaryColor);
      }
    }
  }

  &.center {
    .pwidget {
      left: calc(50% - 190px) !important;
      margin: auto !important;
    }
  }

  &.left {
    .widget-button-wrapper {
      left: 20px;
    }

    .pwidget {
      left: 20px;
    }

    .widget-tooltip-wrapper {
      left: 20px;
      right: auto;

      .widget-tooltip {
        &:after {
          display: none;
        }

        &:before {
          display: block;
        }
      }
    }
  }

  &.right {
    .widget-button-wrapper {
      left: auto;
      right: 20px;
    }

    .pwidget {
      left: auto;
      right: 20px;
    }

    .widget-tooltip-wrapper {
      left: auto;
      right: 20px;

      .widget-tooltip {
        &:after {
          display: block;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .widget-button-wrapper {
    position: absolute;
    bottom: 20px;
    left: 60px;
  }

  .widget-tooltip-wrapper {
    position: absolute;
    bottom: 100px;

    .widget-tooltip-close {
      box-sizing: content-box;
      position: absolute;
      display: inline-block;
      right: -5px;
      top: -5px;
      background-color: #888;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #fff;
      z-index: 1;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.7);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.7);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    }

    .widget-tooltip {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 13px;
      min-height: 40px;
      max-width: 305px;
      padding: 24px;
      text-align: left;
      color: #000;
      background-color: #fff;
      box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.25);

      >span {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
        display: block;
        max-width: 195px;
      }

      &:after,
      &:before {
        top: 100%;
        position: absolute;
        width: 0;
        height: 0;
        content: "";
        z-index: 2;
        display: none;
        border-top: 7px solid #fff;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }

      .widget-tooltip-logo {
        .profile-img {
          height: auto;
          border-radius: 50%;
          border: 2px solid #ffffff;
          margin-right: 10px;
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;
          flex: 0 0 50px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &:after {
        right: 15px;
      }

      &:before {
        left: 15px;
      }
    }
  }

  .pwidget {
    z-index: 1;
    background-color: #f0f0f0;
    border-radius: 5px;
    display: block;
    max-width: 380px;
    width: 380px;
    position: absolute;
    left: 20px;
    bottom: 110px;
    max-height: calc(100vh - 180px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

    &__gate-screen,
    &__department-screen {
      list-style: none;
      padding: 0;

      .pwidget__header {
        span {
          color: #fff;
          display: block;

          &.title {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
          position: relative;
          display: block;
          background-color: #fff;
          border: 1px solid #dedede;
          padding: 5px 15px;
          border-radius: 8px;
          margin-top: 20px;
          cursor: pointer;
          text-align: left;

          &:first-of-type {
            margin-top: 0;
          }

          span {
            color: #666;
          }

          .gate-icon {
            background-color: var(--primaryColor);
            box-shadow: none !important;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            display: inline-block;
            padding: 8px;
            margin-right: 15px;

            img {
              width: 25px;
              height: 25px;
            }
          }

          &:hover {
            box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
          }

          &::after {
            content: "";
            position: absolute;
            background-image: url("../../assets/images/widget/right.webp");
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            top: 15px;
            right: 15px;
          }
        }
      }
    }

    &__department-screen {
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 15px;

          &::after {
            position: relative;
            top: 0px;
            right: 0px;
            margin-left: 10px;
          }
        }
      }
    }

    &__header {
      background-color: var(--primaryColor);
      display: flex;
      align-items: center;
      padding: 15px 20px;
      border-radius: 4px 4px 0px 0px;
      justify-content: space-between;

      .close-icon {
        right: -5px;
        top: -5px;
        background-color: #888;
        border-radius: 50%;
        flex: 0 0 25px;
        height: 25px;
        color: #fff;
        align-items: center;
        justify-content: center;
        border: 3px solid #fff;
        z-index: 1;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.7);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);

        span {
          display: block;
        }

        ;
      }

      .profile-img {
        height: auto;
        border-radius: 50%;
        border: 2px solid #ffffff;
        min-width: 60px;
        min-height: 60px;
        max-width: 60px;
        max-height: 60px;
        flex: 0 0 60px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      }

      span.title {
        display: block;
        color: #fff;
        text-align: left;
        padding-left: 10px;
        line-height: 20px;
        font-weight: 500;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Ubuntu, "Helvetica Neue", Helvetica, sans-serif;
      }
    }

    &__main-screen {
      max-height: calc(100vh - 180px - 140px);
      overflow: auto;

      .pwidget__content {
        .message-box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 15px;
          margin-bottom: 15px;

          .message-box__inner {
            text-align: left;
            max-width: 80%;
            min-width: 80%;
            padding: 15px 15px;
            display: block;
            border-radius: 5px;
            border-radius: 25px;
            background-color: var(--lightBgColor);
          }

          &.to {
            justify-content: flex-end;

            .message-box__inner {
              background-color: rgba(151, 186, 216, 0.25098) !important;
              border-bottom-right-radius: 0;
            }
          }

          &.from {
            .message-box__inner {
              border-bottom-left-radius: 0;
            }
          }

          &.reply {
            .message-box__inner {
              background-color: #fff !important;
              text-align: center;
              padding: 0;

              .reply-title {
                font-weight: bold;
                padding: 10px 0;
                border-radius: 25px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                color: #fff;
                background-color: var(--primaryColor);
              }

              .reply-body {
                padding: 20px 10px;

                .reply-body-title {
                  font-weight: bold;
                  padding-bottom: 10px;
                }

                .reply-body-content {}
              }

              .reply-number {
                font-weight: bold;
                padding-top: 15px;
                font-size: 16px;
              }
            }
          }

          &.action-wrapper {
            .message-box__inner {
              background-color: #fff !important;
            }
          }
        }
      }
    }

    &__content {
      margin: 0;
      padding: 20px;

      // max-height: calc(100vh - 180px - 50px - 90px);
      // min-height: 450px;
      .inner-content-wrapper {
        margin: 15px 0;
      }

      .title {
        padding: 10px 0 20px;
        display: block;
        text-align: left;
      }

      .head-line {
        color: #535457;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        padding: 0 10px 10px;
        display: block;
        text-align: center;
      }

      .action-button {
        background-color: var(--primaryColor);
        border: none;
        color: white;
        box-sizing: border-box;
        padding: 8px 15px;
        border-radius: 5px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        max-width: 60%;

        &:hover {
          outline: none;
          -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .pwidget-datetime-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;

        .pwidget-form-input-group {
          border-bottom: 1px solid #757575 !important;
          box-sizing: border-box;
          width: 100%;
          text-align: left;
          font-weight: bold;
          display: flex;
          border-radius: 0px;

          span[class*="pwidget-icon-"] {
            font-size: 18px;
            padding: 2px 5px;
            cursor: pointer;
            display: flex;
            align-items: center;

            svg {
              width: 20px;

              path {
                fill: var(--primaryColor);
              }
            }
          }

          .pwidget-form-input-schedule-time {
            width: 100%;
          }

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      .pwidget-message-wrapper {
        margin-bottom: 15px;
      }

      .pwidget-record-wrapper {
        text-align: center;
        margin-top: 20px;

        .record-icon {
          display: inline-block;
          border-radius: 50%;
          padding: 20px;
          background: #fb6a6a;
          border: none;
          cursor: pointer;
          margin: auto;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .pwidget-dropdown {
        /* Size & position */
        position: relative;
        width: 100%;
        text-align: left;
        margin: 0 auto;
        padding: 0;

        /* Styles */
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease-out;
        font-weight: normal;

        &:after {
          /* Little arrow */
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          top: 50%;
          right: 8px;
          margin-top: -3px;
          border-width: 6px 6px 0 6px;
          border-style: solid;
          border-color: var(--primaryColor) transparent;
        }

        .pwidget-dropdown-label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 5px;

          .pwidget-dropdown-placeholder {
            display: inline-block;
            padding-left: 12px;
          }
        }

        .pwidget-dropdown-inner {
          /* Size & position */
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 100;

          /* Styles */
          background: #fff;
          border-radius: 0 0 5px 5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-top: none;
          border-bottom: none;
          list-style: none;
          transition: all 0.3s ease-out;

          /* Hiding */
          max-height: 0;
          overflow: hidden;

          -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
        }

        .pwidget-dropdown-inner .pwidget-dropdown-inner-li {
          padding: 0 10px;
          display: block;
          text-decoration: none;
          color: #535457;
          padding: 8px 0;
          transition: all 0.3s ease-out;
          border-bottom: 1px solid #e6e8ea;
          text-align: center;

          &:hover,
          &.selected {
            background-color: rgba(#3578c6, 0.3);
          }

          &:last-of-type {
            border: none;
          }

          &[disabled] {
            background-color: #848484;
            color: #fff;
            cursor: not-allowed;
          }
        }

        &.active {
          border-radius: 5px 5px 0 0;
          background: #3578c6;
          box-shadow: none;
          border-bottom: none;
          color: white;

          &:after {
            border-color: #fff transparent;
          }

          .pwidget-dropdown-inner {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            max-height: 220px;
            overflow-y: scroll;
          }

          .pwidget-icon path {
            fill: var(--primaryColor);
          }
        }
      }

      .action-link {
        text-decoration: underline;
        text-align: center;
        font-size: 15px;
        text-decoration: underline;
        user-select: none;
        font-weight: normal;
        display: block;
        padding: 10px 0;
        color: #535457 !important;
      }

      .gdpr-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 15px;
        &.promotion-wrapper {
          padding-bottom: 30px;
        }

        .custom-checkbox-wrapper {
          margin-right: 10px;

          .checkbox-custom {
            border-color: #535457 !important;
          }

          input:checked~.checkbox-custom {
            background-color: #535457 !important;
            border-color: #535457 !important;
          }
        }

        p {
          margin: 0;
          font-size: 13px;
          color: #666;
          line-height: 18px;
          font-weight: normal;
          text-align: left;
          cursor: pointer;
        }
      }
    }

    &__footer {
      padding: 15px 10px 5px;

      .screen-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px 0 20px;

        a {
          color: #fff;
          font-size: 16px;
          display: inline-block;
          padding: 3px 30px;
          border-radius: 5px;
          background-color: var(--primaryColor);
          opacity: 0.6;

          &:hover {
            outline: none !important;
            -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5) !important;
            -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5) !important;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5) !important;
            opacity: 1 !important;
            text-decoration: none;
          }

          &.active {
            opacity: 1;
          }

          img {
            width: 16px;
            margin-right: 10px;
          }
        }
      }
    }

    &__main_footer {
      .powered_by {
        height: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        border-radius: 0px 0px 4px 4px;

        a {
          color: var(--primaryColor);
          padding-left: 6px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.install-page {
  .department-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
    padding-right: 20px;

    .form-group {
      margin-bottom: 0;
    }
  }

  .landline-modal {
    label {
      pointer-events: unset;
      overflow: inherit;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .tooltip-icon {
        font-size: 16px;
        margin-left: 5px;
        display: inline-block;
        margin-top: calc(4px - 8px);
      }
    }

    .button-tooltip {
      margin: 0;
      padding: 0;
      top: 4px;
      display: block;
      position: relative;
    }
  }

  .widget-type-select {
    .multiselect {
      border: 2px solid $rb-blue !important;
    }

    .multiselect__content-wrapper {
      outline: 2px solid $rb-blue !important;
      border: none !important;
      border-radius: 0 !important;
    }
  }

  .officeHours .form-group .input-icon {
    pointer-events: none;
  }

  .widget-phone-number {
    margin-bottom: 10px;
    display: block;

    .form-group {
      margin: 0;
    }

    .vti__dropdown {
      background-color: #f2f2f2;
    }

    .vti__dropdown-list {
      display: none;
    }

    .form-group .vue-tel-input {
      border-radius: 5px;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      font-weight: bold;
      border-radius: 5px;
      font-size: 20px !important;
      line-height: 50px;
      height: 50px;
      color: rgb(92, 92, 92);

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 18px !important;
        line-height: 50px;
        height: 50px;
        text-align: left;
        color: #ced4da;
        font-style: italic;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 18px !important;
        line-height: 50px;
        height: 50px;
        text-align: left;
        color: #ced4da;
        font-style: italic;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 18px !important;
        line-height: 50px;
        height: 50px;
        text-align: left;
        color: #ced4da;
        font-style: italic;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 18px !important;
        line-height: 50px;
        height: 50px;
        text-align: left;
        color: #ced4da;
        font-style: italic;
      }
    }
  }

  .form-wizard-tab-content {

    .caller-id-block,
    .landline-switch-block {
      .vuestic-switch {
        margin-top: 0;
      }

      label {
        pointer-events: unset;
        overflow: inherit;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .tooltip-icon {
          font-size: 16px;
          margin-left: 5px;
          display: inline-block;
          margin-top: calc(4px - 8px);
        }
      }
    }
  }

  .verify-modal-body {
    .widget {
      box-shadow: none !important;
      text-align: center;
      margin-bottom: 0;

      .widget-body {
        padding: 0;
      }
    }

    .resend-call-link {
      color: #3578c7;
    }
  }

  .widget-input {
    border: 1px solid #ced4da !important;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-weight: bold;
    color: #535457 !important;
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 0.0625rem solid #ced4da;
    padding: 5px 10px;
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px !important;
    outline: none;
    color: #5c5c5c;
    font-family: inherit;
    font-weight: bold;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 19px !important;
      // line-height: normal;
      height: 42px;
      text-align: left;
      color: #ced4da;
      font-style: italic;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 19px !important;
      line-height: 42px;
      height: 42px;
      text-align: left;
      color: #ced4da;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-size: 19px !important;
      // line-height: normal;
      height: 42px;
      text-align: left;
      color: #ced4da;
      font-style: italic;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 19px !important;
      line-height: 42px;
      height: 42px;
      text-align: left;
      color: #ced4da;
      font-style: italic;
    }
  }

  .vcard-preview-wrapper {
    .vcard-preview {
      width: 400px;
      height: 640px;
      background-image: url("../../assets/images/vcard-preview.webp");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      justify-content: center;
      margin-left: 50px;
      .vcard-logo {
        background-color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 85px;
        height: 85px;
        top: 94px;
        border-radius: 50%;
      }
      span {
        display: block;
        position: absolute;
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #4283cb;
        font-weight: 700;
        &.vcard-name {
          width: 100%;
          text-align: center;
          top: 188px;
          font-weight: 600;
          font-size: 19px;
          color: #000;
        }
        &.vcard-phone {
          top: 326px;
          left: 48px;
        }
        &.vcard-email {
          top: 396px;
          left: 48px;
        }
        &.vcard-website {
          top: 466px;
          left: 48px;
        }
      }
    }
  }
}
</style>