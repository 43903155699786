<template>
  <div>
    <div class="form-group twilio-phone form-group-w-btn">
      <div class="input-group has-margin-top" v-if="value">
        <label class="control-label" >Phone Number
          <a v-b-tooltip.hover title="This is the number you and your customers will see when someone generates a call from your website" class="fa fa-question-circle tooltip-icon" />
        </label>
        <phone-number-input 
          name="Phone Number"
          v-model="iValue"
          placeholder="Phone Number"
          :disabled="true"
        />
      </div>
      <div v-if="!value || twilioStatus === 'invalid'" class="input-group has-margin-top" style="width: auto;" :class="{'ml-1': value}">
        <label class="control-label" >Select Phone Number
          <a v-b-tooltip.hover title="This is the number you and your customers will see when someone generates a call or requests sms from your website." class="fa fa-question-circle tooltip-icon" />
        </label>
        <button class="btn btn-sm btn-primary btn-assign" @click="onOpenSearchModal">Assign Number</button>
      </div>
    </div>
    <vuestic-modal
      :isOpen="isOpenModalSearch"
      @ok="handleAssignPhoneNumber"
      @cancel="closeModalSearch"
      :okDisabled="assignButtonDisabled"
      :closeOnOk="false"
      okText="Assign"
      :force="true"
      cancelText="Cancel"
    >
      <span slot="title">Assign Your Number</span>
      <span v-if="!customNumberEnabled">
        <div class="form-group twilio-phone form-group-w-btn">
          <div class="input-group has-margin-top">
            <phone-number-input 
              name="Area Code"
              v-model="areaCode"
              placeholder="Area Code"
              :allowValidation="false"
              :maxLen = 5
              @onInputChange="onInputChange"
              @onCountryChange="onCountryChange"
            />
            <label class="control-label" >Area Code
              <a v-b-tooltip.hover :title="getAreaCodeHelp" class="fa fa-question-circle tooltip-icon" />
            </label>
          </div>
          <button class="btn btn-sm btn-primary ml-2" :disabled="loading" @click="searchPhoneNumber">Search</button>
        </div>
        <b-alert class="err-message text-center" v-if="errorMessage" show variant="danger" v-html="errorMessage"></b-alert>
        <vuestic-widget class="number-widget" :loading="loading" headerText="">
          <div v-if="phoneNumbers.length" class="number-item" v-for="(phone,index) in phoneNumbers">
            <custom-radio-box :key="index" :id="`id_${index}`" :modelValue="phoneSelectedIndex" :value="phone.phone_number" name="phoneNumberRadio" @input="onNumberSelect(index)">
              <span class="number">{{phone.phone_number}}</span>
              <span class="number-capability">
                <i v-bind:class="{ active: phone.capabilities.voice }" class="fa fa-phone" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'voice')"></i>
                <i v-bind:class="{ active: phone.capabilities.sms }" class="fa fa-comments" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'sms')"></i>
                <i v-bind:class="{ active: phone.capabilities.mms }" class="fa fa-image" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'mms')"></i>
              </span>
              <span class="number-price" v-if="showPrice(phone)">{{phone.price_unit}} {{phone.price.current_price}}</span>
              <span class="number-address" v-if="phone.address_requirements != 'none'"><i class="fa fa-address-card" v-b-tooltip.hover title="Address Verification Required"></i></span>
              <span class="number-type" v-if="phone.number_type == 'toll free'">( Tollfree )</span>
            </custom-radio-box>
          </div>
          <span v-if="initSearch && !phoneNumbers.length" class="d-block text-center">No Result Found.</span>
          <div v-show="phoneSelectedIndex != null && phoneNumbers[phoneSelectedIndex].address_requirements != 'none'">
            <fieldset>
              <legend>Address</legend>
              <div class="row">
                <div class="col-md-6">
                  <text-input name="Street" label="Street" placeholder="Street" v-model="address.street" required/>
                </div>
                <div class="col-md-6">
                  <text-input name="City" label="City" placeholder="City" v-model="address.city" required/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <text-input name="Region" label="Region" placeholder="Region" v-model="address.region" required/>
                </div>
                <div class="col-md-6">
                  <text-input name="PostalCode" label="Postal Code" placeholder="Postal Code" v-model="address.postal_code" required/>
                </div>
              </div>
            </fieldset>
          </div>
        </vuestic-widget>
      </span>
      <vuestic-tabs :names="tabNames" v-model="tabSelected" v-if="customNumberEnabled">
        <span :slot="tabNames[0]">
          <div class="col-md-12" v-if="!['US', 'CA'].includes(countryCode)">
            <b-alert class="err-message text-center" show variant="primary">For text messaging it is always best practices to use numbers within the same country as the end user's device. We can't guarantee users can receive text messages from countries outside of <span><b>{{ twilioNumberCountry }}</b></span> (a different country than your marketing number).</b-alert>
          </div>
          <div class="form-group twilio-phone form-group-w-btn">
            <div class="input-group has-margin-top">
              <phone-number-input 
                name="Area Code"
                v-model="areaCode"
                placeholder="Area Code"
                :allowValidation="false"
                :maxLen = 5
                @onInputChange="onInputChange"
                @onCountryChange="onCountryChange"
              />
              <label class="control-label" >Area Code
                <a v-b-tooltip.hover :title="getAreaCodeHelp" class="fa fa-question-circle tooltip-icon" />
              </label>
            </div>
            <button class="btn btn-sm btn-primary ml-2" :disabled="loading" @click="searchPhoneNumber">Search</button>
          </div>
          <b-alert class="err-message text-center" v-if="errorMessage" show variant="danger" v-html="errorMessage"></b-alert>
          <vuestic-widget class="number-widget" :loading="loading" headerText="">
            <div v-if="phoneNumbers.length" class="number-item" v-for="(phone,index) in phoneNumbers">
              <custom-radio-box :key="index" :id="`id_${index}`" :modelValue="phoneSelectedIndex" :value="phone.phone_number" name="phoneNumberRadio" @input="onNumberSelect(index)">
                <span class="number">{{phone.phone_number}}</span>
                <span class="number-capability">
                  <i v-bind:class="{ active: phone.capabilities.voice }" class="fa fa-phone" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'voice')"></i>
                  <i v-bind:class="{ active: phone.capabilities.sms }" class="fa fa-comments" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'sms')"></i>
                  <i v-bind:class="{ active: phone.capabilities.mms }" class="fa fa-image" v-b-tooltip.hover :title="getCapbilityTooltip(phone, 'mms')"></i>
                </span>
                <span class="number-price" v-if="showPrice(phone)">{{phone.price_unit}} {{phone.price.current_price}}</span>
                <span class="number-address" v-if="phone.address_requirements != 'none'"><i class="fa fa-address-card" v-b-tooltip.hover title="Address Verification Required"></i></span>
                <span class="number-type" v-if="phone.number_type == 'toll free'">( Tollfree )</span>
              </custom-radio-box>
            </div>
            <span v-if="initSearch && !phoneNumbers.length" class="d-block text-center">No Result Found.</span>
            <div v-show="phoneSelectedIndex != null && phoneNumbers[phoneSelectedIndex].address_requirements != 'none'">
              <fieldset>
                <legend>Address</legend>
                <div class="row">
                  <div class="col-md-6">
                    <text-input name="Street" label="Street" placeholder="Street" v-model="address.street" required/>
                  </div>
                  <div class="col-md-6">
                    <text-input name="City" label="City" placeholder="City" v-model="address.city" required/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <text-input name="Region" label="Region" placeholder="Region" v-model="address.region" required/>
                  </div>
                  <div class="col-md-6">
                    <text-input name="PostalCode" label="Postal Code" placeholder="Postal Code" v-model="address.postal_code" required/>
                  </div>
                </div>
              </fieldset>
            </div>
          </vuestic-widget>
        </span>
        <span :slot="tabNames[1]">
          <vuestic-widget class="number-widget custom-number-widget" :loading="loadingCustom" headerText="">
            <b-alert class="err-message text-center" v-if="errorMessageCustom" show variant="danger" v-html="errorMessageCustom"></b-alert>
            <div v-if="errorMessageAssign" class="text-center">
              <b-alert class="err-message text-center" show variant="warning" v-html="errorMessageAssign"></b-alert>
              <button class="btn btn-sm btn-primary btn-assign" @click="onContinueAssign">Continue</button>
            </div>
            <div class="form-group twilio-phone form-group-w-btn">
              <div class="input-group has-margin-top">
                <phone-number-input 
                  name="Twilio Phone Number"
                  v-model="twilioNumber"
                  placeholder="Twilio Phone Number"
                  :allowValidation="true"
                  @onInputChange="onInputChange"
                  @onCountryChange="onCountryChange"
                  @onNumberValid="onNumberValid"
                />
                <label class="control-label" >Twilio Phone Number
                  <a v-b-tooltip.hover :title="getTwilioNumberHelp" class="fa fa-question-circle tooltip-icon" />
                </label>
              </div>
            </div>
          </vuestic-widget>
        </span>
      </vuestic-tabs>
    </vuestic-modal>
  </div>
</template>

<script>
import CustomRadioBox from "./CustomRadioBox";

export default {
  components: {
    CustomRadioBox,
  },
  props: [
    'value',
    'installId',
    'landlineEnabled',
    'twilioStatus',
  ],

  data () {
    return {
      initSearch: false,
      areaCode: '',
      countryCode: '',
      loading: false,
      loadingCustom: false,
      phoneNumbers: [],
      phoneSelectedIndex: null,
      isOpenModalSearch: false,
      address: {},
      errorMessage: '',
      errorMessageCustom: '',
      errorMessageAssign: '',
      twilioNumber: '',
      twilioNumberDuplicated: '',
      twilioNumberValid: false,
      tabNames: [
        'Search Number',
        'Custom Number'
      ],
      tabSelected: 'Search Number',
      numberType: null,
    }
  },

  mounted() { 
    if (this.landlineEnabled)
    {
      this.tabNames[1] = 'Custom / Landline Number'
    }
    // this.areaCode = this.value
    this.initAddress()
  },

  methods: {
    initAddress() {
      this.address = {
        street: '',
        city: '',
        region: '',
        postal_code: '',
      } 
    },

    showPrice(phone) {
      return phone.price_unit && phone.price
    },

    getCapbilityTooltip(item, type) {
      let tooltip = ''
      if (type == 'sms') {
        tooltip = 'SMS'
      } else if (type == 'voice') {
        tooltip = 'Voice'
      } else if (type == 'mms') {
        tooltip = 'MMS'
      }
      if (item.capabilities[type])
        tooltip += ' Enabled'
      else
        tooltip += ' Disabled'
      return tooltip
    },
    onNumberSelect(index){
      this.phoneSelectedIndex = index
      const phone = this.phoneNumbers[index]
      if (!phone.capabilities.sms)
      {
        this.errorMessage = "SMS feature won't be available for this number."
      } else {
        this.errorMessage = null
      }
    },

    closeModalSearch() {
      this.isOpenModalSearch = false
    },

    onInputChange(args) {
      this.countryCode = args.regionCode
    },

    onCountryChange(country){
      this.countryCode = country
    },

    onNumberValid(value) {
      this.twilioNumberValid = value
    },

    onOpenSearchModal() {
      this.phoneNumbers = []
      this.phoneSelectedIndex = null
      this.errorMessage = ''
      this.isOpenModalSearch = true
      this.initSearch = false;
    },

    searchPhoneNumber() {
      this.phoneSelectedIndex = null
      this.loading = true
      this.errorMessage = ''
      this.phoneNumbers = []

      this.initAddress()

      const payload = {
        areaCode: this.areaCode,
        countryCode: this.countryCode
      }
      
      this.$store.dispatch('install/searchPhoneNumber', payload)
        .then(({ data }) => {
          this.loading = false
          if (data && data.data.length) {
            if (!data.isAreaCode)
            {
              this.errorMessage = `There are currently no numbers with area code ${this.areaCode} available. Here are some other numbers with ${this.areaCode} in the number. Try searching a near by area code.`
            }
            this.phoneNumbers = data.data
          } else {
            this.errorMessage = "There isn't any phone number available with requested area code.<br/> Please try with another one or add Custom Number if you have!"
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    handleAssignPhoneNumber () {
      if (this.tabSelected == this.tabNames[0])
      {
        this.loading = true
        this.errorMessage = ''
        this.initSearch = true;
        this.numberType = 'origin'
        let payload = {
          installId: this.installId,
          number: this.phoneNumbers[this.phoneSelectedIndex].phone_number,
          countryCode: this.phoneNumbers[this.phoneSelectedIndex].country_code,
        }
        if (this.phoneNumbers[this.phoneSelectedIndex].address_requirements != 'none')
        {
          payload['address'] = this.address
        }
        this.phoneSelectedIndex = null;

        this.$store.dispatch('install/purchasePhoneNumber', payload)
          .then(({ data }) => {
            this.loading = false
            if (data) {
              const { number, capabilities } = data
              // this.$emit('input', number)
              this.$emit('onChange', {
                ...data,
                type: this.numberType
              })
              this.isOpenModalSearch = false;
            } else {
              this.showToast(
                'There is no phone number available in that area code please try another one. ',
                { icon: 'fa-exclamation-triangle' },
              )
            }
          })
          .catch(error => {
            this.loading = false
            this.errorMessage = error.response.data.message
          })
      } else if (this.tabSelected == this.tabNames[1])
      {
        this.loadingCustom = true
        this.errorMessageCustom = ''
        let payload = {
          installId: this.installId,
          number: this.twilioNumber
        }
        this.$store.dispatch('install/checkNumber', payload)
          .then(({ data }) => {
            this.loadingCustom = false
            if (data) {
              const { ids, type } = data
              this.numberType = type
              if (ids && ids.length)
              {
                this.errorMessageAssign = 'This number is already in use by Install #' + ids.join(', #') + '. Are you sure you want to use this number? We will detach this number from other installs.'
                this.twilioNumberDuplicated = this.twilioNumber;
              } else {
                this.twilioNumberDuplicated = this.twilioNumber;
                this.onContinueAssign()
              }
              this.twilioNumber = ''
            } else {
              this.showToast(
                'Something wrong with twilio number, Try with different one.',
                { icon: 'fa-exclamation-triangle' },
              )
            }
          })
          .catch(error => {
            this.loadingCustom = false
            this.errorMessageCustom = error.response.data.message
          })
      }
    },

    onContinueAssign() {
      this.errorMessageAssign = ''
      this.loadingCustom = true
      const payload = {
        installId: this.installId,
        number: this.twilioNumberDuplicated,
        isForce: true
      }
      this.twilioNumberDuplicated = ''

      const url = this.numberType === 'hosted' ? 'install/assignLandlineNumber' : 'install/assignTwilioNumber'

      this.$store.dispatch(url, payload)
        .then(({ data }) => {
          this.loadingCustom = false
          if (data) {
            const { number, capabilities } = data
            // this.$emit('input', number)
            this.$emit('onChange', {
              ...data,
              type: this.numberType
            })
            this.isOpenModalSearch = false;
          } else {
            this.showToast(
              'Something went wrong. Try again later.',
              { icon: 'fa-exclamation-triangle' },
            )
          }
        })
        .catch(error => {
          this.loadingCustom = false
          this.errorMessageCustom = error.response.data.message
        })
    }
  },
  computed: {
    iValue: {
      get () { return this.value; },
      set (v) {
        this.$emit('input', v)
      },
    },

    countries() {
      return this.$store.getters['countryWithCode']
    },

    twilioNumberCountry() {
      return this.countries[this.countryCode]
    },

    customNumberEnabled () {
      return this.$store.state.auth.user && this.$store.state.auth.user.business && this.$store.state.auth.user.business.custom_number_enabled
    },

    getAreaCodeHelp() {
      return "Place an area code or part of a phone number. Leave blank and we will suggest random number."
    },
    getTwilioNumberHelp() {
      return "Place a custom twilio number."
    },
    assignButtonDisabled() {
      return this.tabSelected == this.tabNames[0] && this.phoneSelectedIndex == null || 
        this.tabSelected == this.tabNames[1] && !this.twilioNumberValid ||
        this.loading
    }
  }
};
</script>
<style lang="scss">
  .twilio-phone .form-group{
    margin-bottom: 0;
  }
  .form-group.twilio-phone .control-label {
    color: #333;
    font-size: 0.6rem !important;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
  }
  .form-group.twilio-phone input {
    color: #3578c6;
    &:disabled {
      cursor: not-allowed;
      background-color: #fff;
    }
  }
  .form-group.form-group-w-btn .btn {
    align-self: flex-start;
    margin-top: 20px;
  }
  .number-item {
    span {
      color: #555;
      display: inline-block;
    }
    span.number {
      min-width: 150px;
    }
    span.number-capability,
    span.number-price,
    span.number-address {
      min-width: 100px;
      margin-left: 20px;
    }
    span.number-address
    {
      i {
        display: inline-block;
        margin-left: 10px;
        font-size: 22px;
        color: #dc0027;
      }
    }
    span.number-capability {
      i {
        display: inline-block;
        margin-left: 10px;
        font-size: 22px;
        &:first-of-type {
          margin-left: 0px;
        }
        &.active {
          color: #009c00;
        }
      }
    }
  }
  .number-widget {
    box-shadow: none !important;
    margin: 0;
    padding: 0;
    &.custom-number-widget {
      .widget-body {
        padding: 0;
      }
    }
  }
</style>

<style scoped>
  .form-group .control-label {
    pointer-events: unset;
    overflow: inherit;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .tooltip-icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
    margin-top: calc( 4px - 8px);
  }
  .input-group .btn-assign {
    margin: 0;
    border-radius: 0;
  }
</style>
